<!--<footer class="footer-area footer-bg2 pt-100">-->
<footer class="footer-area footer-bg pt-100">
    <div class="container">
        <div class="footer-midal pb-70">
            <div class="row">
                <div class="col-lg-4 col-sm-7">
                    <div class="footer-widget">
                        <div class="footer-img">
                            <img loading="lazy" style="width: 200px !important;height: 94px;"  src="assets/img/logo/logof.png" alt="Images">
                        </div>
                        <p>We take pride in providing innovative and reliable solutions to meet our customer needs.
                        </p>
                        <div class="footer-social-icon">
                            <ul class="social-link">
                                <li><a href="https://www.linkedin.com/company/anstek-inc/" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="https://twitter.com/anstek_utah" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="https://www.instagram.com/Anstek_Inc/" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                                <li><a href="https://www.facebook.com/people/Anstek-INC/100091035071175/" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
<!--                                <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> -->
<!--                                <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-5">
                    <div class="footer-widget">
                        <h3>Explore Links</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/">Home</a></li>
                            <li><a routerLink="/about">About Us</a></li>
                            <li><a routerLink="/services">Services</a></li>
                            <li><a routerLink="/careers">Careers</a></li>
                            <li><a routerLink="/faq">FAQ</a></li>
                            <li><a routerLink="/blog">Blogs</a></li>
                            <li><a routerLink="/contact" >Contact Us</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-7">
                    <div class="footer-widget pl-5">
                        <h3>Services</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/services">IT Consulting</a></li>
                            <li><a routerLink="/services">Web Design</a></li>
                            <li><a routerLink="/services">Case Studies</a></li>
                            <li><a routerLink="/services">App Development</a></li>
                            <li><a routerLink="/services">Web Development</a></li>
                            <li><a routerLink="/services">Artificial Intelligence</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget">
                        <h3>Address</h3>

                        <ul class="footer-list-two">
                            <li><i class='flaticon-place'></i>520 Central Pkwy E, Ste 106<br>Plano, TX 75074</li>
                            <li><i class='flaticon-email-1'></i><a href="mailto:info@anstek.com">info@anstek.com</a></li>
                            <li><i class='flaticon-telephone'></i><a href="tel:+13853644242"> +1 (385) 364-4242</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copy-right-area">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-4">
                    <div class="copy-right-text text-start">
                        <p>© 2023 by Anstek. All rights reserved.</p>

                            <!-- <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p> -->
                    </div>
                </div>

<!--                <div class="col-lg-4">-->
<!--                    <div class="copy-right-list">-->
<!--                        <ul>-->
<!--                            <li><a routerLink="/privacy-policy"> Privacy Policy</a></li>-->
<!--                            <li><a routerLink="/terms-conditions"> Terms & Conditions</a></li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</footer>
