import { Component, OnInit , ElementRef } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import {environment} from "../environments/environment.prod";
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {

    location: any;
    routerSubscription: any;
    // title = 'anstek';
    ///chat bot

    title = 'Simple Chatbot';
    messageArray = [];
    synth:any;
    voices:any;
    showChat = false;
    constructor(private router: Router,private _el: ElementRef) {

        let baseUrl = 'http://localhost:4200/#/';
        if (environment.production){
            baseUrl = 'http://utahtechcenter.com/anstek/#/'
        }

        ///set href value for mobile design navs
        // console.log(baseUrl, 'window.location.url');
        // $('#about').attr('href', baseUrl+ '/#/about');
        // $('#services').attr('href', baseUrl +'/#/services');
        // $('#careers').attr('href', baseUrl +'/#/careers');
        // $('#faq').attr('href', baseUrl+'/#/faq');
        // $('#blog').attr('href', baseUrl +'/#/blog');
        // $('#contact').attr('href', baseUrl +'/#/contact');
    }

    message= '';

    ngOnInit(){
        this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.preloader').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('assets/js/custom.js');
            $('.preloader').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }


    getType(value){
        console.log(value);
        return typeof value;
    }

    public scrollToBottom() {
        const el: HTMLDivElement = this._el.nativeElement;
        el.scrollTop = Math.max(0, el.scrollHeight - el.offsetHeight);
      }
    
}
