<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Careers</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Careers</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img loading="lazy" src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img loading="lazy" src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img loading="lazy" src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img loading="lazy" src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="about-widget-area pb-70 ">
    <div class="container-fluid">
        <div class="row custom-class">
            <div class="col-12 text-center">
                <h3><span class="color-brand-2" style="color: #3C65F5;position: relative;">{{collectionSize}} Jobs</span> Available Now</h3>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 contact-form" style="max-width: 100%;max-height: 440px;padding: 22px;">
                <div class="section-title">
                    <span class="sp-before sp-after">Search for Jobs</span>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <i class='bx bx-briefcase'></i>
                            <input type="text" class="form-control"
                                   [(ngModel)]="searchControl"
                                   (input)="searchFilter()"
                                  required placeholder="Search ...">
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <i class='bx bx-category'></i>
                            <select class="form-control form-select">
                                <option>--select--</option>
                                <option selected>Technology</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12">
                        <label>Location</label>
                        <div class="form-group">
                            <i class='bx bx-location-plus'></i>
                            <select  [(ngModel)]="selectedLocation" class="form-control form-select">
<!--                                <option *ngFor="let loc of locationList;" -->
<!--                                        [value]="loc?.location_id">{{loc?.location_name}}</option>-->
                                <option value="0" selected>USA</option>
                                <option value="1">INDIA</option>
                                <option value="2">NETHARLAND</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn border-radius"
                                >SEARCH <i class='bx bx-plus'></i></button>
                    </div>
                </div>
            </div>
            <div id="careersList1" class="col-md-8 col-sm-12 d-flex flex-column job_listings ">
                <ng-container *ngFor="let row of filteredJobs">
                <div  class="col-sm-12 mt-4 jobbox-grid-item mobile-mode" *ngIf="row.status != 0 ">
                            <div class="card-grid-2 hover-up" >
                                <div class="card-grid-2-image-left">
<!--                                    <span class="flash"></span>-->
<!--                                    <div class="image-box">-->
<!--                                        <img src="assets/img/logo/anstekLogo.png" width="52" alt="Fireworks">-->
<!--                                    </div>-->
                                    <div class="right-info">
                                        <a  (click)="careerDetailsPage(row)"
                                            style="cursor: Pointer;text-decoration: underline;    text-decoration-color: #ff5203;">
                                            <span class="name-job" style="color:#ff2500">{{row?.title}}</span>
                                        </a>
                                        <br>
<!--                                        <span  class="location-small">{{(row.location_name && row?.location_name != '') ? row.location_name : 'N/A'}}</span>-->
                                    </div>
                                </div>

                                <div class="card-block-info">
                                    <div class="d-flex align-items-center gap-3 font-xs color-text-mutted">
<!--                                        <span><i class="fi-rr-briefcase ms-0 me-1"></i>{{row?.shift_type}}</span>-->
                                        <span><i class="fi-rr-clock ms-0 me-1"></i><time datetime="2022-09-27">Posted {{row.postal_date}}</time></span>
                                    </div>

                                    <p class="font-sm color-text-paragraph mt-15" [innerHTML]="row.short_description.slice(0,60)"></p>

                                    <div class="mt-15 d-flex flex-wrap gap-5">
<!--                                     <span *ngIf="row?.skills != ''" class="btn btn-grey-small"-->
<!--                                      >-->
<!--                                                {{row?.skills}}-->
<!--                                            </span>-->
                                        <span class="btn btn-grey-small"
                                              >
                                              <i class="bx bx-location-plus  ms-0 me-1"></i>&nbsp; {{row.location_name}}
                                            </span>
                                    </div>



                                    <div class="card-2-bottom mt-30">
                                        <div class="row align-items-center">

                                            <div class="col-12 text-end">
                                                <button style="padding-right: 22px" class="default-btn border-radius"
                                                  data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm"
                                                >Apply now </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
<!--                //full view-->
                 <div class="col-12 mt-3 jobbox-list-item web-mode">
                            <div class="card-grid-2 hover-up">
<!--                                <span class="flash"></span>-->
                                <div class="row">

                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="card-grid-2-image-left">
<!--                                            <div class="image-box">-->
<!--                                                <img src="assets/img/logo/anstekLogo.png" width="52" alt="Fireworks">-->
<!--                                            </div>-->
                                            <div class="right-info">
                                                <a  (click)="careerDetailsPage(row)" style="cursor: Pointer;text-decoration: underline;    text-decoration-color: #ff5203;">
                                                    <span class="name-job" style="color:#ff2500">{{row?.title}}</span>
                                                </a>
<!--                                                <br>-->
<!--                                                <span *ngIf="row.location_name"-->
<!--                                                      class="location-small">{{row.location_name }}</span>-->

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 mr-3 text-start text-md-end pr-60 col-md-6 col-sm-12">
                                        <div class="pl-15 mb-15 mt-30 d-flex flex-wrap gap-5 justify-content-sm-center">
                                            <span class="btn btn-grey-small text-capitalize" style="margin: 0 10px"
                                            >
                                                <i class="bx bx-location-plus ms-0 me-1"></i> &nbsp;{{row.location_name }}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 mr-3 text-start text-md-end pr-60 col-md-6 col-sm-12">
                                        <div class="pl-15 mb-15 mt-30 d-flex flex-wrap gap-5 justify-content-sm-end">
                                            <div class="d-flex align-items-center gap-3 font-xs color-text-mutted">
                                                <span *ngIf="row?.shift_type && row?.shift_type != ''"><i class="bx bx-briefcase ms-0 me-1"></i>{{row?.shift_type}}</span>
                                                <span><i class="bx bx-time ms-0 me-1"></i><time datetime="2022-09-27">Posted {{row?.postal_date}}</time></span>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="card-block-info">
<!--                                    <div class="d-flex align-items-center gap-3 font-xs color-text-mutted">-->
<!--                                        <span><i class="bx bx-briefcase ms-0 me-1"></i>{{row?.shift_type}}</span>-->
<!--                                        <span><i class="bx bx-time ms-0 me-1"></i><time datetime="2022-09-27">Posted {{row?.postal_date}}</time></span>-->
<!--                                    </div>-->

                                    <p class="font-sm color-text-paragraph mt-10" [innerHTML]="row?.short_description.slice(0, 170)"></p>

                                    <div class="card-2-bottom mt-20">
                                        <div class="row align-items-center">
                                            <div class="col-lg-7 col-7">
<!--                                                <span class="card-text-price">$500</span><span class="font-xs text-muted">/Hour-->
<!--                                            </span>				-->
                                            </div>
                                            <div class="col-lg-5 col-5 text-end">
                                                <button style="padding-right: 22px" class="default-btn border-radius"
                                                data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm"
                                                >Apply now </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                  </div>
                    <div class="modal fade" role="dialog" id="ModalApplyJobForm" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="ModalApplyJobFormLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="exampleModalLabel" style="background-color:white;color: blue;">Job application</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="applyjobForm.reset('')" aria-label="Close"></button>
                                </div>
                                <div class="modal-body text-danger">
                                    <form [formGroup]="applyjobForm" method="post">
                                        <div class="input input-animate">
                                            <!-- <h5  class="name-job">{{row?.title}}</h5> -->
                                            <span class="spin"></span>
                                        </div>
                                        <div class="form-group mb-3">
                                            <input class="form-control" type="text" readonly

                                                   value="{{row?.title}}"

                                            >
                                        </div>
                                        <div class="form-group mb-3">
                                            <input type="text" class="form-control"
                                                   formControlName="name" required placeholder="Your Name*"
                                                   [ngClass]="{'is-invalid': !applyjobForm.get('name').valid && (applyjobForm.get('name').dirty || applyjobForm.get('name').touched)}"
                                            >
                                            <label *ngIf="!applyjobForm.get('name').valid && (applyjobForm.get('name').dirty || applyjobForm.get('name').touched)"
                                                   class="error jquery-validation-error small form-text invalid-feedback">Name is Required.
                                            </label>
                                        </div>
                                        <div class="form-group mb-3">
                                            <input type="email" class="form-control"
                                                   formControlName="email" required placeholder="E-mail*"
                                                   [ngClass]="{'is-invalid': !applyjobForm.get('email').valid && (applyjobForm.get('email').dirty || applyjobForm.get('email').touched)}"
                                            >
                                            <label *ngIf="!applyjobForm.get('email').valid && (applyjobForm.get('email').dirty || applyjobForm.get('email').touched)"
                                                   class="error jquery-validation-error small form-text invalid-feedback">Email is Required.
                                            </label>
                                        </div>
                                        <div class="form-group mb-3">
                                            <input type="text" class="form-control"
                                                   formControlName="phone_number" placeholder="Your Phone*"
                                                   [ngClass]="{'is-invalid': !applyjobForm.get('phone_number').valid && (applyjobForm.get('phone_number').dirty || applyjobForm.get('phone_number').touched)}"
                                            >
                                            <label *ngIf="!applyjobForm.get('phone_number').valid && (applyjobForm.get('phone_number').dirty || applyjobForm.get('phone_number').touched)"
                                                   class="error jquery-validation-error small form-text invalid-feedback">Phone number is Required.
                                            </label>
                                        </div>
                                        <div class="form-group mb-3">
                                                    <textarea  class="form-control" formControlName="message"  cols="30" rows="8"  placeholder="Your Message*"
                                                               [ngClass]="{'is-invalid': !applyjobForm.get('message').valid && (applyjobForm.get('message').dirty || applyjobForm.get('message').touched)}">
                                                    </textarea>
                                            <label *ngIf="!applyjobForm.get('message').valid && (applyjobForm.get('message').dirty || applyjobForm.get('message').touched)"
                                                   class="error jquery-validation-error small form-text invalid-feedback">Message is Required..
                                            </label>
                                        </div>
                                        <div class="mb-3 ">
                                            <input accept="application/pdf" formControlName="resume"  (change)="browseFile($event)" required=""  type="file" id="resume"
                                                   [ngClass]="{'is-invalid': !applyjobForm.get('resume').valid && (applyjobForm.get('resume').dirty || applyjobForm.get('resume').touched)}">
                                            <p>Upload your resume file. File types : .pdf and Max file size : 1mb</p>
                                            <label *ngIf="!applyjobForm.get('resume').valid && (applyjobForm.get('resume').dirty || applyjobForm.get('resume').touched)"
                                                   class="error jquery-validation-error small form-text invalid-feedback">Please choose file.
                                            </label>
                                        </div>
                                        <div  class="text-center">
                                            Do you need support?
                                            <a (click)="contact()" style="color: blue;cursor: pointer;" data-bs-dismiss="modal"><u>Contact US</u></a>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-danger" (click)="applyjobForm.reset('')" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" class="default-btn border-radius" style="padding: 4px 15px 5px 15px;" (click)="applyjob(row)">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
                <div  class="col-lg-12 col-md-12" *ngIf="jobList.length > 0">
                    <app-pagination
                            [totalRecords]="collectionSize" [recordsPerPage]="this.pageSize" (onPageChange)="displayActivePage($event)">
                    </app-pagination>
            </div>
<!--            <div class="row">-->
<!--                <div class="col-lg-8 col-md-10 ml-auto mr-auto">-->
<!--                    <span><strong>Current Active Page = </strong> {{activePage}}</span>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        </div>
    </div>


<app-footer-style-two></app-footer-style-two>
