// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiHost: 'http://utahtechcenter.com/erp-backend/api/index.php/',
  apiHost: 'https://anstek.com/anstek-backend/api/index.php/',
  imgUrl: 'https://anstek.com/anstek-backend/',
  // apiHost: 'http://20.231.216.128/erp-backend/api/index.php/',
  // imgUrl: 'http://20.231.216.128/erp-backend/',
  // apiHost: 'http://localhost:8888/anstek-backend/api/index.php/',
  // imgUrl: 'http://localhost:8888/anstek-backend/',
  sessionPrefix: 'anstek'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
