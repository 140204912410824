import {Injectable} from '@angular/core';
import {
    HttpInterceptor,
    HttpResponse,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {environment} from "../../environments/environment";

@Injectable()
export class ServerHttpInterceptor implements HttpInterceptor {
    allow: boolean;
    apiHost: string;

    constructor(private auth: AuthService) {
        this.apiHost = environment.apiHost;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // this.helper.showLoader(true);

        // const requestUrl = this.apiHost + req.url;
        const requestUrl = this.apiHost + req.url;
        const authReq = req.clone(
            {
                url: requestUrl,
                setHeaders: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}
            });
        console.log(requestUrl, 'requestUrl');
        console.log(authReq, 'authReq');
        return next.handle(authReq)
            .pipe(
                tap(
                    event => {
                        const eve: any = event;
                        if (event instanceof HttpResponse) {
                            if (event.body && event.body.IsSuccess) {
                               // this.helper.showLoader(false);
                            } else {
                               // this.helper.showLoader(false);
                            }
                        }
                        if (eve.status === 401) {
                            // this.auth.logout();
                        }
                    },
                    error => {
                        if (error.status === 401) {
                            // this.auth.logout();
                        }
                        if (error instanceof HttpErrorResponse) {
                            if (error.error instanceof ErrorEvent) {
                                console.error('Error Event');
                            }
                        }
                        // this.helper.showLoader(false);
                    }
                )
            );
    }
}
