<!--<nav aria-label="page navigation example">-->
<!--    <ul class="pagination">-->
<!--        <li class="page-item" (click)="onClickPage(activePage - 1)">-->
<!--            <a class="page-link" href="javascript:void(0);">«-->
<!--            Previous</a></li>-->
<!--        <li class="page-item" [ngClass]="{'active': activePage === item}" *ngFor="let item of pages"-->
<!--            (click)="onClickPage(item)"><a class="page-link" href="javascript:void(0);">{{item}}</a></li>-->
<!--        <li class="page-item" (click)="onClickPage(activePage + 1)"><a class="page-link" href="javascript:void(0);">Next-->
<!--            »</a></li>-->
<!--    </ul>-->
<!--</nav>-->
<div class="pagination-area text-center">
   <a (click)="onClickPage(activePage - 1)" class="prev page-numbers cursor">
        <i class="bx bx-chevron-left"></i></a>

    <span class="page-numbers cursor" aria-current="page" [ngClass]="{'current': activePage === item}"
          *ngFor="let item of pages" (click)="onClickPage(item)">{{item}}</span>
    <a class="next page-numbers cursor" (click)="onClickPage(activePage + 1)"><i class="bx bx-chevron-right"></i></a>
</div>
