import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/shared/services/auth.service';
import { urls } from 'src/shared/utils/urls';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;

  constructor( private fb: FormBuilder, private auth: AuthService) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name : ['', Validators.required],
      email : ['', Validators.required],
      phone_number : ['', Validators.required],
      msg_subject : ['', Validators.required],
      message: ['', Validators.required],
    });
  }
  addMessage(form){
    Object.keys(form.controls).forEach(val => {
      form.controls[val].markAsTouched({onlySelf: true});
    });
    let payload: any;
    if (form.valid) {
      payload = {
        platform: 'web',
         role_id: '1',
         user_id: '1',
         name: this.contactForm.get('name').value,
         email_id : this.contactForm.get('email').value,
         phone_number : this.contactForm.get('phone_number').value,
         message: this.contactForm.get('message').value,
         company_id: '1',
      };
      console.log(payload ,'payload ');
       let url = urls.contactUs;
       this.auth.postService(payload, url).subscribe(
        (successData: any) => {
          if (successData.IsSuccess) {
            // this.toast.success(successData.ResponseObject, '');
            this.contactForm.reset();
          } else {
            // this.toast.error(successData.ErrorObject, '');
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
}
