import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {environment} from "../../../../environments/environment";
import {SessionConstants} from "../../../../shared/utils/sessionConstants";
import * as serviceData from "../../../../assets/json/mock-service.json";

interface Service {
  id: Number;
  title: String;
  cover_img: String;
  short_desc: String;
  title_two: String;
  short_desc_two: String;
  key_point: any;
  short_desc_three;
}
@Component({
  selector: 'app-services-details',
  templateUrl: './services-details.component.html',
  styleUrls: ['./services-details.component.scss']
})
export class ServicesDetailsComponent implements OnInit {

  serviceData : any='';
  webhost: any;
  servicesList: Service[] = serviceData;
  constructor(public sanitizer: DomSanitizer) {
    this.webhost = environment.imgUrl;
  }

  ngOnInit(): void {
    this.serviceData = JSON.parse(localStorage.getItem(SessionConstants.serviceDetails));
    console.log(this.serviceData,'blog');
    fetch('../../../../assets/json/mock-service.json').then(res => res.json())
        .then(jsonData => {
          this.servicesList = jsonData;
          this.servicesList.length = 5;
          console.log(this.servicesList ,'stueents');
        });

  }

  serviceDetailsPage(data){
    localStorage.setItem(SessionConstants.serviceDetails, JSON.stringify(data));
    this.serviceData = JSON.parse(localStorage.getItem(SessionConstants.serviceDetails));
  }


}
