<div class="brand-logo-area  pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img style="width: 135px; height: 45px;" src="assets/img/brand/pic1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img style="width: 125px; height: 55px;" src="assets/img/brand/pic2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img style="width: 135px; height: 45px;" src="assets/img/brand/pic3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img style="width: 135px; height: 45px;" src= "assets/img/brand/intel.png"alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img style="width: 135px; height: 45px;" src="assets/img/brand/amazon1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img style="width: 135px; height: 45px;"  src="assets/img/brand/pic4.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>