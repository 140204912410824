<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>About Us</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img loading="lazy" src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img loading="lazy" src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img loading="lazy" src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img loading="lazy" src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="about-widget-area pt-50 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-widget-img">
                    <img loading="lazy" src="assets/img/about/about-img4.png" alt="">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content-two">
                    <div class="section-title">
                        <span class="sp-before sp-after">About Us</span>
                        <h2>Unlock Your Potential with Us</h2>
                    </div>
                    <h3>"Our team of experienced professionals with hard"</h3>
                    <!-- <h3>"Powering Your Business Through Software Solutions"</h3> -->
                    <p>We are dedicated to provide innovative and high-quality solutions that help our clients improve their business. Our team of experienced professionals works hard to create software that meets the highest standards of quality and reliability. Our solutions are designed to be user-friendly and accessible, making them easy to use and navigate for our clients. With a focus on customer service and satisfaction, we strive to provide our clients with the best possible service and support.
                        We look forward to working with you.</p>
                    <p>We will be on your list of favorite because of our increased efficiency, reduced costs and remarkable customer service. Anstek instantly provides innovative solutions, technical support and training to help businesses and organizations to get the most out of the software services we provide.</p>
                    <!-- <div class="row">
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="about-list">
                                <i class='bx bx-check'></i>
                                <span>01</span>
                                <p>Retail Solutions</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="about-list">
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>Data Management</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="about-list">
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>Data Visualization</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="about-list">
                                <i class='bx bx-check'></i>
                                <span>04</span>
                                <p>Business Geist</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="about-list">
                                <i class='bx bx-check'></i>
                                <span>05</span>
                                <p>Data Analysis</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="about-list">
                                <i class='bx bx-check'></i>
                                <span>06</span>
                                <p>Data Warehousing</p>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <!-- <div class="col-lg-6 col-md-12">
                <div class="about-widget-content">
                    <div class="section-title">
                        <span class="sp-before sp-after">About Us</span>
                        <h2>Grow Your Business With Our Agency</h2>
                    </div>
                    <h3>We have 30 yearsOur strategy includes consistently evolving, to ensure we’re producing exceptional SEO for business.</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tetur adipisicing saliqua.</p>
                    <div class="about-widget-list">
                        <ul>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>01</span>
                                <p>High standards of professionalism, integrity. Establishment of close working relationships.</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>A different way of thinking, both inside and outside the box. We do not have to be the best.</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>We do not have to be the best, we do have to give our best Ability to learn. Multicultural competence.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="about-video">
                        <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=6s" class="video-btn popup-btn"><i class='flaticon-forward'></i><span>Play Video </span></a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>

<div class="counter-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>7 Years</h3>
                    <p>GLORIOUS YEARS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>100%</h3>
                    <p>SATISFACTION RATE</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>100+</h3>
                    <p>TEAM MEMBERS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>200+</h3>
                    <p>TOTAL PROJECTS</p>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-shape-top">
        <img loading="lazy" src="assets/img/counter/counter-shape.png" alt="Images">
    </div>
</div>

<div class="service-list-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">The Essence</span>
            <h2>Our Journey’s Path</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>Our History</h3>
                    <p>We have a rich history of providing innovative solutions since 2017.  We have also been instrumental in the development of artificial intelligence. Looking to the future, we will continue to stay at the forefront of innovation and technology.</p>
                    <!-- <ul>
                        <li><i class='bx bx-check'></i> Activate Listening</li>
                        <li><i class='bx bx-check'></i> Better Best Wow!</li>
                        <li><i class='bx bx-check'></i> Brilliant Minds</li>
                        <li><i class='bx bx-check'></i> Activate Listening</li>
                    </ul> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>Our Mission</h3>
                    <p>The mission is to provide businesses with the tools and technology they need to succeed in an ever-changing digital world. Our team is committed in providing the highest quality product and cutting-edge technology to ensure our clients reach their fullest potential.</p>
                    <!-- <ul>
                        <li><i class='bx bx-check'></i> Creating Results.</li>
                        <li><i class='bx bx-check'></i> Good Thinking</li>
                        <li><i class='bx bx-check'></i> Expect More</li>
                        <li><i class='bx bx-check'></i> Brilliant Minds</li>
                    </ul> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>Why Choose Us?</h3>
                    <p> At our Anstek, With our experienced team of professionals, we provide comprehensive software development services that are tailored to each individual customer. Our team is dedicated in creating customised  solutions that are tailored specifically to meet those needs.</p>
                    <!-- <ul>
                        <li><i class='bx bx-check'></i> We Have You Covered</li>
                        <li><i class='bx bx-check'></i> Stay Real Always</li>
                        <li><i class='bx bx-check'></i> We Turn Heads</li>
                        <li><i class='bx bx-check'></i> Stay Real Always</li>
                    </ul> -->
                </div>
            </div>

           
        </div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Working Process</span>
            <h2 class="h2-color2">Simple & Clean Work Process</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>1 </span>Research Product</h3>
                            <p>This involves gathering information about the needs and preferences of stakeholders, such as customers, end-users, and business owners, to define the requirements for the product or service. </p>
                        </li>
                        <li>
                            <h3><span>2 </span>Development </h3>
                            <p>In technology, development usually refers to the process of creating software, applications, websites, or other digital products.</p>
                        </li>
                       
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img loading="lazy" src="assets/img/work-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>3</span>User Testing </h3>
                            <p>This involves testing the product or service to ensure that it meets the specified requirements, is free of defects, and performs as expected. </p>
                        </li>
                        <li>
                            <h3><span>4</span>Product Handover</h3>
                            <p>A successful product handover ensures that the product is delivered on time, within budget, and to the required quality standards.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="history-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Our History</span>
            <h2>The Facts About Our Company</h2>
        </div>

        <div class="history-list">
            <ul>
                <li class="active">
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2017</h3>
                        <span class="text-capitalize">We Commenced</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2019</h3>
                        <span class="text-capitalize">prepped firmly</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2020</h3>
                        <span class="text-capitalize">attracted interest</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2022</h3>
                        <span class="text-capitalize">became reliable</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2023</h3>
                        <span class="text-capitalize">here we are!</span>
                    </div>
                </li>
            </ul>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="history-content">
                    <h2 class="text-capitalize">Factors underlying our success</h2>
                    <p>Our great success is the ability to automate tasks and processes. We invest in research and development to stay ahead of the competition. This could include investing in new technologies, testing and refining existing products, or developing new products.</p>
                    <p>Our main focus is to establish trusting ties with our clients, which entails providing customer service, soliciting their opinions, and answering their queries.</p>
<!--                    <a routerLink="/about" class="default-btn">Learn more <i class='bx bx-plus'></i></a>-->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="history-img">
                    <img loading="lazy" src="assets/img/history-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="team-area-two pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Team</span>
            <h2 class="h2-color2">Meet Our Data Scientist to Grow Your Business</h2> 
        </div>

        <div class="team-slider-two owl-carousel owl-theme">
            <div class="team-card active">
                <img src="assets/img/team/team-img7.jpg" alt="Images">
                <div class="content">
                    <h3>Cristiono Kopper</h3>
                    <span>Web Developer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated">
                <img src="assets/img/team/team-img8.jpg" alt="Images">
                <div class="content">
                    <h3>Jermin Jekson</h3>
                    <span>Marketing Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card">
                <img src="assets/img/team/team-img9.jpg" alt="Images">
                <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2">
                <img src="assets/img/team/team-img10.jpg" alt="Images">
                <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card active">
                <img src="assets/img/team/team-img11.jpg" alt="Images">
                <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2">
                <img src="assets/img/team/team-img12.jpg" alt="Images">
                <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="team-two-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape7"><img src="assets/img/shape/shape14.png" alt="Images"></div>
    </div>
</div> -->
<div class="testimonial-area-two pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="testimonial-content">
                    <div class="testimonial-content1">
                        <div class="testimonial-text">
                            <h3>7 Years</h3>
                            <span>GLORIOUS YEARS</span>
                        </div>
                    </div>

                    <div class="testimonial-content2">
                        <div class="testimonial-text">
                            <h3>100%</h3>
                            <span>SATISFACTION RATE</span>
                        </div>
                    </div>

                    <div class="testimonial-content3">
                        <div class="testimonial-text">
                            <h3>100+</h3>
                            <span>TEAM MEMBERS</span>
                        </div>
                    </div>

                    <div class="testimonial-content4">
                        <div class="testimonial-text">
                            <h3>200+</h3>
                            <span>TOTAL PROJECTS</span>
                        </div>
                    </div>

                    <div class="testimonial-shape1">
                        <img loading="lazy" src="assets/img/shape/shape15.png" alt="Images">
                    </div>
                    
                    <div class="testimonial-shape2">
                        <img loading="lazy" src="assets/img/shape/shape16.png" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="testimonial-slider-area">
                    <div class="testimonial-title-2">
                        <h2>Words From Customers</h2>
                    </div>

                    <div class="testimonial-slider-two owl-carousel owl-theme">
                        <div class="testimonial-item-2">
                            <p>The customer service representatives were incredibly helpful and knowledgeable. I was quickly able to get going, thanks to them.
                                </p>
                            <div class="content mt-5">
                                <!-- <img src="assets/img/testimonial/testimonial-img1.png" alt="Images"> -->
                                <div class="content-title">
                                    <!-- <h3>David McLean</h3> -->
                                    <!-- <span>CEO & Manager</span> -->
                                </div>
                            </div>
                        </div>

                        <div class="testimonial-item-2">
                            <p>The customer service representatives were incredibly helpful and knowledgeable. I was quickly able to get going, thanks to them.</p>
                            <div class="content">
                                <!-- <img src="assets/img/testimonial/testimonial-img2.png" alt="Images"> -->
                                <div class="content-title">
                                    <!-- <h3>Jermin Jekson</h3> -->
                                    <!-- <span>Marketing Manager</span> -->
                                </div>
                            </div>
                        </div>

                        <div class="testimonial-item-2">
                            <p>The customer service representatives were incredibly helpful and knowledgeable. I was quickly able to get going, thanks to them.</p>
                            <div class="content">
                                <!-- <img src="assets/img/testimonial/testimonial-img3.png" alt="Images"> -->
                                <div class="content-title">
                                    <!-- <h3>Julfiker Jeain</h3>
                                    <span>CEO At Ostino</span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="testimonial-area testimonial-area-mb ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="testimonial-list">
                    <div class="list-img-1">
                        <img src="assets/img/testimonial/testimonial1.png" alt="Images">
                    </div>

                    <div class="list-img-2">
                        <img src="assets/img/testimonial/testimonial2.png" alt="Images">
                    </div>

                    <div class="list-img-3">
                        <img src="assets/img/testimonial/testimonial3.png" alt="Images">
                    </div>

                    <div class="list-img-4">
                        <img src="assets/img/testimonial/testimonial4.png" alt="Images">
                    </div>

                    <div class="list-img-5">
                        <img src="assets/img/testimonial/testimonial5.png" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="testimonial-title">
                    <h2>Words From Customers</h2>
                </div>

                <div class="testimonial-slider owl-carousel owl-theme">
                    <div class="testimonial-item">
                        <p>The customer service representatives were incredibly helpful and knowledgeable. I was quickly able to get going, thanks to them.</p>
                        
                    </div>

                    <div class="testimonial-item">
                        <p>The customer service representatives were incredibly helpful and knowledgeable. I was quickly able to get going, thanks to them.</p>
                        < <div class="content">
                            <img src="assets/img/testimonial/testimonial-img2.png" alt="Images">
                            <div class="content-title">
                                <h3>Jermin Jekson</h3>
                                <span>Marketing Manager</span>
                            </div>
                        </div> -->
                    <!-- </div>

                    <div class="testimonial-item">
                        <p>The customer service representatives were incredibly helpful and knowledgeable. I was quickly able to get going, thanks to them.</p>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> --> 

<div class="brand-logo-area  pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 45px;" src="assets/img/brand/pic1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 125px; height: 55px;" src="assets/img/brand/pic2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 45px;" src="assets/img/brand/pic3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 45px;" src= "assets/img/brand/intel.png"alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 45px;" src="assets/img/brand/amazon1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 45px;"  src="assets/img/brand/pic4.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="project-area">
    <div class="container">
        <div class="project-content">
            <h2>To decode the Power of Technology!</h2>
            <a routerLink="/contact" class="project-btn">Get started <i class='bx bx-plus'></i></a>
        </div>
    </div>

    <div class="project-shape">
        <div class="shape1"><img loading="lazy" src="assets/img/shape/shape17.png" alt=""></div>
        <div class="shape2"><img loading="lazy" src="assets/img/shape/shape18.png" alt=""></div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
