import { Component, OnInit } from '@angular/core';
import { SessionConstants } from 'src/shared/utils/sessionConstants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/shared/services/auth.service';
import { urls } from 'src/shared/utils/urls';
import {DomSanitizer} from "@angular/platform-browser";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-career-details',
  templateUrl: './career-details.component.html',
  styleUrls: ['./career-details.component.scss']
})
export class CareerDetailsComponent implements OnInit {
  careerData : any='';
  applyjobForm: FormGroup;
  webHost: any;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private fb: FormBuilder,private router: Router, public sanitizer:DomSanitizer,private auth:AuthService ) { 
    this.webHost = environment.imgUrl;
  }

  ngOnInit(): void {
    this.careerData = JSON.parse(localStorage.getItem(SessionConstants.careersDetails));
    console.log(this.careerData,'careerData');
    this.applyjobForm = this.fb.group({
      name : ['', Validators.required],
      email : ['', Validators.required],
      phone_number : ['', Validators.required],
      message: ['', Validators.required],
      resume:['', Validators.required],
      fileSource: ['',Validators.required],
      // job_title:['']
    });
  }
    browseFile(event) {
        const reader = new FileReader();
        const finalData = [];
        const max = 1;
        let validfiles = [...event.target.files];
        validfiles?.forEach(async (item , index) => {
            const fileSize = item.size;
            const fileMb = fileSize / 1024 / 1024;
            if (fileMb >= max) {
                alert(`File size exceeds ${max} MiB`);
            } else {
                const res = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(item);
                    reader.onload = () => {
                        finalData.push({name : item.name , type: item.type, image: reader.result, size: item.size});
                        resolve(reader.result);
                    };
                    reader.onerror = error => reject(error);
                });
                if (validfiles.length === finalData.length){
                    console.log(finalData ,'final data');
                    let files = [];
                    for (const item of finalData) {
                        console.log(item, 'item');
                        item.progress = 0;
                        const split = item.image.split(',');
                        item.image = split[1];
                        files.push(item);
                    }
                    this.applyjobForm.patchValue({
                        fileSource: files
                    });
                }
            }
        });
    }

  applyjob(value){
    console.log(this.applyjobForm.value,'value');
    Object.keys(this.applyjobForm.controls).forEach(val => {
      this.applyjobForm.controls[val].markAsTouched({onlySelf: true});
    });
    let payload: any;
    if (this.applyjobForm.valid) {
      payload = {
        platform: 'web',
         role_id: '1',
         user_id: '1',
         full_name: this.applyjobForm.get('name').value,
         email_id : this.applyjobForm.get('email').value,
         contact_number : this.applyjobForm.get('phone_number').value,
         message: this.applyjobForm.get('message').value,
         company_id: '1',
         resume:this.applyjobForm.get('fileSource').value,
         job_title: value.title, 
      };
      console.log(payload ,'payload ');
       let url = urls.applyJob;
       this.auth.postService(payload, url).subscribe(
        (successData: any) => {
          if (successData.IsSuccess) {
            // this.toast.success(successData.ResponseObject, '');
            this.applyjobForm.reset();
          } else {
            // this.toast.error(successData.ErrorObject, '');
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  contact(){
    this.router.navigate(['/contact']);
  }

}
