<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Blogs</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Blogs</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img loading="lazy" src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img loading="lazy" src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img  loading="lazy" src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img loading="lazy" src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="blog-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Our Blogs</span>
            <h2 class="h2-color2">Latest Valuable Insights</h2>
<!--            <p class="margin-auto">Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim.</p>-->
        </div>

        <div class="row d-flex flex-row pt-45">
            <div class="col-lg-4 col-md-6" *ngFor="let row of filteredBlogs;let i = index">
                <div class="blog-card">
                    <a routerLink="/blog">
                        <img style="width: 550px;height: 246px; " [src]="sanitizer.bypassSecurityTrustResourceUrl(baseImageUrl  + row.cover_image!)"  alt="Images">
<!--                        <img loading="lazy" style="width: 550px;height: 246px; " src="assets/img/blog/blogsample.jpeg"  alt="Images">-->
                    </a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 09 April 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog" (click)="blogDetailsPage(row)" >{{row.title}}</a></h3>
                    </div>
                </div>
            </div>


            <div  class="col-lg-12 col-md-12" *ngIf="blogList.length > 0">
                <app-pagination
                        [totalRecords]="collectionSize" [recordsPerPage]="this.pageSize" (onPageChange)="displayActivePage($event)">
                </app-pagination>
            </div>
        </div>
    </div>

    <div class="blog-shape">
        <div class="shape1"><img loading="lazy" src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img loading="lazy" src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img loading="lazy" src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img loading="lazy" src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div>

<app-brands></app-brands>

<!-- <div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-two></app-footer-style-two>
