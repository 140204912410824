import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/shared/services/auth.service';
import { urls } from 'src/shared/utils/urls';
import {DomSanitizer} from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import * as serviceData from '../../../../assets/json/home-service.json';
import {SessionConstants} from "../../../../shared/utils/sessionConstants";

interface Service {
  id: Number;
  title: String;
  cover_img: String;
  short_desc: String;
  title_two: String;
  short_desc_two: String;
  key_point: any;
  short_desc_three;
}

@Component({
  selector: 'app-ai-startup',
  templateUrl: './ai-startup.component.html',
  styleUrls: ['./ai-startup.component.scss']
})
export class AiStartupComponent implements OnInit {
  blogList: any;
  webhost: any;
  servicesData: Service[] = serviceData;
  servicesFilterData: Service[];


  collectionSize = 0;
  activePage:number = 1;
  pageSize = 6;

  constructor(private auth: AuthService, public sanitizer: DomSanitizer) { 
    this.webhost = environment.imgUrl;


  }

  ngOnInit(): void {
    this.getblogList();

    fetch('../../../../assets/json/mock-service.json').then(res => res.json())
        .then(jsonData => {
          this.servicesData = jsonData;
          console.log(this.servicesData ,'stueents');
          this.collectionSize = this.servicesData.length;
          this.displayActivePage(1);
        });

  }


  getblogList(){
    const url = urls.blogList;
    let payload: any = {
        platform: 'web',
        role_id: 1,
        user_id: 1,
    }
    this.auth.postService(payload, url).subscribe(
      (successData: any) => {
        if (successData.IsSuccess) {
          // this.toast.success(successData.ResponseObject, '')
          this.blogList = successData.ResponseObject;
        } else {
          // this.toast.error(successData.ErrorObject, '');
        }
      },
      (err) => {
        console.log(err);
      }
  )
  }

  serviceDetailsPage(data){
    localStorage.setItem(SessionConstants.serviceDetails, JSON.stringify(data));
}

   displayActivePage(activePageNumber:number){
     this.servicesFilterData = [];
     let arr = JSON.parse(JSON.stringify(this.servicesData));
     let nextNumber: any;
     let currentNumber = (activePageNumber-1) * this.pageSize;
     nextNumber = (activePageNumber) * this.pageSize;
     for (let i=currentNumber;i < nextNumber;i++){
      if(this.servicesFilterData.length < this.pageSize && i < arr.length){
        this.servicesFilterData.push(arr[i]);
      }
     }
      this.activePage = activePageNumber;
    }

}
