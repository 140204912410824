import { Component, OnInit } from '@angular/core';
import * as serviceData from '../../../../assets/json/mock-service.json';
import {SessionConstants} from "../../../../shared/utils/sessionConstants";

interface Service {
  id: Number;
  title: String;
  cover_img: String;
  short_desc: String;
  title_two: String;
  short_desc_two: String;
  key_point: any;
  short_desc_three;
}
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  servicesData: Service[] = serviceData;
  servicesFilterData: Service[];

  collectionSize = 0;
  activePage:number = 1;
  pageSize = 6;
  constructor() { }

  ngOnInit(): void {

    fetch('assets/json/mock-service.json').then(res => res.json())
        .then(jsonData => {
          this.servicesData = jsonData;
          console.log(this.servicesData ,'stueents');
          this.collectionSize = this.servicesData.length;
          this.displayActivePage(1);
        });
  }

    serviceDetailsPage(data){
        localStorage.setItem(SessionConstants.serviceDetails, JSON.stringify(data));
    }

  displayActivePage(activePageNumber:number){
    this.servicesFilterData = [];
    let arr = JSON.parse(JSON.stringify(this.servicesData));
    let nextNumber: any;
    let currentNumber = (activePageNumber-1) * this.pageSize;
    nextNumber = (activePageNumber) * this.pageSize;
    for (let i=currentNumber;i < nextNumber;i++){
      if(this.servicesFilterData.length < this.pageSize && i < arr.length){
        this.servicesFilterData.push(arr[i]);
      }
    }
    this.activePage = activePageNumber;
  }
}
