<app-navbar-style-four></app-navbar-style-four>

<div class="banner-area-two">
    <div class="container-fluid">
        <div class="container-max-2">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-12">
                        <div class="banner-content-two">
                            <div class="title">
                                <i  class="flaticon-idea"></i>
                                <span>Anstek for every Need</span>
                            </div> 
                            <h1>Best <b>IT Service </b>Provider,&nbsp; Ahead of the curve!</h1>
                            <p>We believe in providing exceptional customer service, so that you
                                can be confident that your project is in good hands.</p>
                            <div class="banner-content-btn">
                                <a routerLink="/about" class="learn-btn">Learn More <i class='bx bx-plus'></i></a>
                                <!--                            <a href="https://www.youtube.com/watch?v=07d2dXHYb94" class="play-on-btn popup-btn">Play Video <i class='flaticon-forward'></i></a>-->
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12">
                        <div class="banner-img-2">
                            <img  src="assets/img/home-two/home-two-img.png" loading="lazy" alt="Images">
                        </div>
                    </div>
                </div>

        </div>
    </div>

    <div class="banner-bottom-shape">
        <div class="shape-one wow fadeInUp" data-wow-delay=".3s"><img loading="lazy" src="assets/img/home-two/home-two-shape1.png" alt="Images"></div>
        <div class="shape-two wow fadeInUp" data-wow-delay=".5s"><img loading="lazy" src="assets/img/home-two/home-two-shape2.png" alt="Images"></div>
        <div class="shape-three wow fadeInUp" data-wow-delay=".7s"><img loading="lazy" src="assets/img/home-two/home-two-shape3.png" alt="Images"></div>
        <div class="banner-dots1"><img loading="lazy" src="assets/img/home-two/home-two-dots.png" alt="Images"></div>
        <div class="banner-dots2"><img loading="lazy" src="assets/img/home-two/home-two-dots2.png" alt="Images"></div>
    </div>
</div>

<!-- <div class="brand-logo-area mt-5 pt-100 ">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 75px;" src="assets/img/brand/android_logo.svg" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 75px;" src="assets/img/brand/Java.svg" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 155px; height: 75px;" src="assets/img/brand/Firebase_Logo.svg" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 75px;" src="assets/img/brand/Google_Cloud_logo.svg" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 75px;" src="assets/img/brand/Microsoft_Azure_Logo.svg" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 75px;"  src="assets/img/brand/php_icon.svg" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 75px;"  src="assets/img/brand/Amazon_Web_Services_Logo.svg" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 145px; height: 75px;"  src="assets/img/brand/angular_icon.svg" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 75px;"  src="assets/img/brand/React-icon.svg" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 75px;"  src="assets/img/brand/Node.js_logo.svg" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 75px;"  src="assets/img/brand/SPRINGBOOT.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 75px;"  src="assets/img/brand/ionic_logo_icon.svg" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 75px;"  src="assets/img/brand/mongoDb.svg" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img style="width: 135px; height: 75px;"  src="assets/img/brand/microsoft_icon.svg" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 75px;"  src="assets/img/brand/mysql_icon.svg" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 75px;"  src="assets/img/brand/Python.svg" alt="Images">
                </div>
        
            </div>
        </div>
    </div>
</div> -->
<div class="about-area-two pb-70  mt-5 ">
    <div class="container-fluid">
        <div class="container-max">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img-2">
                        <img loading="lazy" src="assets/img/about/about-img3.png" alt="">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content-two">
                        <div class="section-title">
                            <span class="sp-before sp-after">About Us</span>
                            <h2>Unlock Your Potential with Us</h2>
                        </div>
                        <h3>"Powering Your Business Through Software Solutions"</h3>
                        <p>We are dedicated to provide innovative and high-quality solutions that help our clients improve their business. Our team of experienced professionals works hard to create software that meets the highest standards of quality and reliability. Our solutions are designed to be user-friendly and accessible, making them easy to use and navigate for our clients. With a focus on customer service and satisfaction, we strive to provide our clients with the best possible service and support.
                            We look forward to working with you.</p>
                        <div class="about-btn-two">
                            <a routerLink="/about" class="about-more-btn">About Us <i class='bx bx-plus'></i></a>
                            <a routerLink="/contact" class="contact-us-btn">Contact Us <i class='bx bx-plus'></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="counter-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>7 Years</h3>
                    <p>GLORIOUS YEARS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>100%</h3>
                    <p>SATISFACTION RATE</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>100+</h3>
                    <p>TEAM MEMBERS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>200+</h3>
                    <p>TOTAL PROJECTS</p>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-shape-top"><img  loading="lazy" src="assets/img/counter/counter-shape.png" alt="Images"></div>
</div>

<!-- <div class="service-another pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2>We Offer Professional Solutions For Your Business</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6" *ngFor="let row of servicesFilterData;let i = index">
                <div class="services-another-card">
                    <a routerLink="/services-details" (click)="serviceDetailsPage(row)"><img loading="lazy"  style="height:204px;width:306px;" [src]="row.cover_img" alt="Images"></a>
                    <h3><a routerLink="/services-details" (click)="serviceDetailsPage(row)">{{row.title}}</a></h3>
                    <p>{{row.short_desc | slice : 0 : 175}}..<a style="color:#ff2500;"
                        routerLink="/services-details"
                        (click)="serviceDetailsPage(row)">show more</a>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="service-another-shape">
        <div class="shape-1"><img loading="lazy" src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape-2"><img loading="lazy" src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape-3"><img loading="lazy" src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape-4"><img loading="lazy"src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape-5"><img loading="lazy" src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape-6"><img loading="lazy" src="assets/img/shape/shape12.png" alt="Images"></div>
    </div>
</div> -->

<div class="services-widget-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2 class="h2-color">We are Flexible enough to <b>Provide You Best</b></h2>
        </div>
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6" *ngFor="let row of servicesFilterData;let i = index">
                <div class="services-another-card">
                    <a routerLink="/services-details" (click)="serviceDetailsPage(row)"><img loading="lazy"  style="height:204px;width:306px;" [src]="row.cover_img" alt="Images"></a>
                    <h3><a routerLink="/services-details" (click)="serviceDetailsPage(row)">{{row.title}}</a></h3>
                    <p>{{row.short_desc | slice : 0 : 175}}..<a style="color:#ff2500;"
                        routerLink="/services-details"
                        (click)="serviceDetailsPage(row)">show more</a>
                    </p>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6" *ngFor="let row of servicesFilterData;let i = index">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details" (click)="serviceDetailsPage(row)"><img class="float-start" [src]="row.cover_img" alt="Images"></a >
                    <h3 class="text-start"><a routerLink="/services-details" (click)="serviceDetailsPage(row)">{{row.title}}</a></h3>
                    <p class="text-start">{{row.short_desc | slice : 0 : 175}}..<a style="color:#ff2500;"
                                                                                   routerLink="/services-details"
                                                                                   (click)="serviceDetailsPage(row)">show more</a></p>
                    <div class="services-card-shape"
                    ><img loading="lazy" src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details"><img class="float-start" src="assets/img/service/service-icon5.png" alt="Images"></a>
                    <h3 class="text-start"><a routerLink="/services-details">Web Design</a></h3>
                    <p class="text-start">Our team of competent Web designers use their creative and software programming skills to design, build and improve websites.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details"><img class="float-start" src="assets/img/service/service-icon4.png" alt="Images"></a> 
                    <h3 class="text-start"><a routerLink="/services-details">Web Development</a></h3>
                    <p class="text-start">Our web experiences are high-performing, feature-packed and digitally transformative, designed to be user-friendly, and secure.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details"><img class="float-start" src="assets/img/service/service-icon3.png" alt="Images"></a>
                    <h3 class="text-start"><a routerLink="/services-details">Artificial Intelligence</a></h3>
                    <p class="text-start">Our increased focus on infusing data and AI across its offerings that enables 
                        clients to generate higher value from their digital transformation initiatives.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details"><img class="float-start" src="assets/img/service/blockchain.png" alt="Images"></a>
                    <h3 class="text-start"><a routerLink="/services-details">Blockchain Development</a></h3>
                    <p class="text-start">Building business logic to running blockchain nodes, we understand how to develop a decentralized platform for NFTs, metaverse, tokens and dApps.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details"><img class="float-start" src="assets/img/service/dataAnalytics.png" alt="Images"></a>
                    <h3 class="text-start"><a routerLink="/services-details">Data Analytics</a></h3>
                    <p class="text-start">Data analyst is responsible for organizing data related to sales numbers, and logistics. They utilize technical expertise to ensure data is accurate and high-quality.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details"><img class="float-start" src="assets/img/service/cyber-security.png" alt="Images"></a>
                    <h3 class="text-start"><a routerLink="/services-details">Cyber Security</a></h3>
                    <p class="text-start">Cyber threats are growing at an exponential rate globally. The current technologies let us into a complex environment of trade wars and hacktivism.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details"><img class="float-start" src="assets/img/service/web3.png" alt="Images"></a>
                    <h3 class="text-start"><a routerLink="/services-details">Web 3.0</a></h3>
                    <p class="text-start">Our passion is delivering Web 3.0, a decentralized and fair internet where users control their own data, identity and destiny.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details"><img class="float-start" class="float-start" src="assets/img/service/devops.png" alt="Images"></a>
                    <h3 class="text-start"><a routerLink="/services-details">DevOps Services</a></h3>
                    <p class="text-start">DevOps helps us automate the processes between software development and IT teams.It helps organizations reduce costs, and improve service delivery.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div> -->


            <div  class="col-lg-12 col-md-12" *ngIf="servicesData.length > 0">
                <app-pagination
                        [totalRecords]="collectionSize" [recordsPerPage]="this.pageSize" (onPageChange)="displayActivePage($event)">
                </app-pagination>
            </div>
            
        </div>
    </div>
</div>

<div class="service-area-two">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Technologies</span>
            <h2>Our Core Competencies Include.</h2>
        </div>

        <div class="row pt-45">
            <div class="col-md-2 col-6">
                <div class="services-item" >
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img  loading="lazy" src="assets/img/developmentTools/angular.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                </div>
            </div>

            <div class="col-md-2 col-6">
                <div class="services-item">
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img loading="lazy" src="assets/img/developmentTools/aws.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                </div>
            </div>

            <div class="col-md-2 col-6">
                <div class="services-item">
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img loading="lazy" src="assets/img/developmentTools/laravel.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-6">
                <div class="services-item">
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img loading="lazy" src="assets/img/developmentTools/googleCloud.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-6">
                <div class="services-item">
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img loading="lazy" src="assets/img/developmentTools/mySql.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-6">
                <div class="services-item">
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img loading="lazy" src="assets/img/developmentTools/codeIgniter.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-6">
                <div class="services-item">
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img loading="lazy" src="assets/img/developmentTools/ai.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-6">
                <div class="services-item">
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img loading="lazy" style= "width: 67px;" src="assets/img/developmentTools/expressJs.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-6">
                <div class="services-item">
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img loading="lazy" style= "width: 67px;"src="assets/img/developmentTools/flutter.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-6">
                <div class="services-item">
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img loading="lazy"  style= "width: 67px;"src="assets/img/developmentTools/react.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-6">
                <div class="services-item">
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img loading="lazy"  style= "width: 67px;"src="assets/img/developmentTools/nodeJs.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-6">
                <div class="services-item">
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img loading="lazy"  style= "width: 67px;"src="assets/img/developmentTools/swift.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="service-two-shape">
        <div class="shape-in1"><img loading="lazy" src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape-in2"><img loading="lazy" src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape-in3"><img loading="lazy" src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape-in4"><img loading="lazy" src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape-in5"><img loading="lazy" src="assets/img/shape/shape12.png" alt="Images"></div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Working Process</span>
            <h2 class="h2-color2">Simple & Clean Work Process</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>1 </span>Research Product</h3>
                            <p>To meet the requirements for the product or service being offered,this necessitates obtaining data on the wants and preferences of stakeholders, including clients,end-users, and business owners. </p>
                        </li>
                        <li>
                            <h3><span>2 </span>Development </h3>
                            <p>In technology, development usually refers to the process of creating software, applications, websites, or other digital products.</p>
                        </li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img loading="lazy" src="assets/img/work-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>3</span>User Testing </h3>
                            <p>This involves testing the product or service to ensure that it meets the specified requirements, is free of defects, and performs as expected. </p>
                        </li>
                        <li>
                            <h3><span>4</span>Product Handover</h3>
                            <p>A successful product handover ensures that the product is delivered on time, within budget, and to the required quality standards.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="serve-area pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="serve-content">
                    <div class="section-title">
                        <span class="sp-before sp-after">Trustworthy</span>
                        <h2 class="h2-color2">"Why Choose Us To Join Our Work?"</h2>
                        <p>At our Anstek, we take pride in providing innovative and reliable solutions to meet our customers’ needs. With our experienced team of professionals, we provide comprehensive software development services that are tailored to each individual customer</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>01</span>
                                <p>Understanding</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>Best Quality</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>Technology</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>04</span>
                                <p>Support 24/7</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>05</span>
                                <p>Expert Team</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>06</span>
                                <p>Price Oriented</p>
                            </div>
                        </div>
                    </div>

                    <a routerLink="/careers" class="default-btn-two">Apply for position <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="serve-img">
                    <img loading="lazy" src="assets/img/serve-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="team-area-two pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Team</span>
            <h2 class="h2-color2">Meet Our Data Scientist to Grow Your Business</h2> 
        </div>

        <div class="team-slider-two owl-carousel owl-theme">
            <div class="team-card active">
                <img src="assets/img/team/team-img7.jpg" alt="Images">
                <div class="content">
                    <h3>Cristiono Kopper</h3>
                    <span>Web Developer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated">
                <img src="assets/img/team/team-img8.jpg" alt="Images">
                <div class="content">
                    <h3>Jermin Jekson</h3>
                    <span>Marketing Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card">
                <img src="assets/img/team/team-img9.jpg" alt="Images">
                <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2">
                <img src="assets/img/team/team-img10.jpg" alt="Images">\
                <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card active">
                <img src="assets/img/team/team-img11.jpg" alt="Images">
                <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2">
                <img src="assets/img/team/team-img12.jpg" alt="Images">
                <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="team-two-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape7"><img src="assets/img/shape/shape14.png" alt="Images"></div>
    </div>
</div> -->

<!-- <div class="testimonial-area-two pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="testimonial-content">

                    <div class="testimonial-content1">
                        <div class="testimonial-text">
                            <h3>7 Years</h3>
                            <span>GLORIOUS YEARS</span>
                        </div>
                    </div>

                    <div class="testimonial-content2">
                        <div class="testimonial-text">
                            <h3>100%</h3>
                            <span>SATISFACTION RATE</span>
                        </div>
                    </div>

                    <div class="testimonial-content3">
                        <div class="testimonial-text">
                            <h3>100+</h3>
                            <span>TEAM MEMBERS</span>
                        </div>
                    </div>

                    <div class="testimonial-content4">
                        <div class="testimonial-text">
                            <h3>200+</h3>
                            <span>TOTAL PROJECTS</span>
                        </div>
                    </div>

                    <div class="testimonial-shape1">
                        <img loading="lazy" src="assets/img/shape/shape15.png" alt="Images">
                    </div>

                    <div class="testimonial-shape2">
                        <img loading="lazy" src="assets/img/shape/shape16.png" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="testimonial-slider-area">
                    <div class="testimonial-title-2">
                        <h2>Words From Customers</h2>
                    </div>

                    <div class="testimonial-slider-two owl-carousel owl-theme">
                        <div class="testimonial-item-2">
                            <p>The customer service representatives were incredibly helpful and knowledgeable. I was quickly able to get going, thanks to them.
                                </p>
                            <div class="content mt-5">
                                <img src="assets/img/testimonial/testimonial-img1.png" alt="Images">
                                <div class="content-title">
                                    <h3>David McLean</h3>
                                    <span>CEO & Manager</span>
                                </div>
                            </div>
                        </div>

                        <div class="testimonial-item-2">
                            <p>The customer service representatives were incredibly helpful and knowledgeable. I was quickly able to get going, thanks to them.</p>
                            <div class="content">
                                <img src="assets/img/testimonial/testimonial-img2.png" alt="Images">
                                <div class="content-title">
                                    <h3>Jermin Jekson</h3>
                                    <span>Marketing Manager</span>
                                </div>
                            </div>
                        </div>

                        <div class="testimonial-item-2">
                            <p>The customer service representatives were incredibly helpful and knowledgeable. I was quickly able to get going, thanks to them.</p>
                            <div class="content">
                                <img src="assets/img/testimonial/testimonial-img3.png" alt="Images">
                                <div class="content-title">
                                    <h3>Julfiker Jeain</h3>
                                    <span>CEO At Ostino</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Our Blogs</span>
            <h2 class="h2-color2">Latest Valuable Insights</h2>
<!--            <p class="margin-auto">Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim</p>-->
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6 mt-0"  *ngFor="let row of blogList;let i = index">
                <div class="blog-card" *ngIf="i<=2">
                    <div *ngIf="i<=2">
                        <a routerLink="/blog">
                            <img  style="width: 550px;height: 246px; "
                                  [src]="sanitizer.bypassSecurityTrustResourceUrl(webhost  + row.cover_image!)"
                                  alt="Images">
                        </a>
<!--                        <a routerLink="/blog-details">-->
<!--                            &lt;!&ndash;                        <img style="width: 550px;height: 246px; " [src]="sanitizer.bypassSecurityTrustResourceUrl(baseImageUrl  + row.cover_image!)"  alt="Images">&ndash;&gt;-->
<!--                            <img loading="lazy" style="width: 550px;height: 246px; " src="assets/img/blog/blogsample.jpeg"  alt="Images">-->
<!--                        </a>-->
                    </div>
                    <div class="content" *ngIf="i<=2">
                        <ul>
                            <li><i class='bx bx-time-five'></i>09 April 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i><a routerLink="/blog">0 Comments</a></li>
                        </ul>
                        <h3><a routerLink="/blog">{{row.title}}</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-md-12 services-another-card1 mt-0 d-flex justify-content-center">
                <a routerLink="/blog" class="learn-btn1">View All <i class='bx bx-plus'></i></a>
            </div>


            <!-- <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog2.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>10 July 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i><a routerLink="/blog">SEO</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">SEO Best Practices Mobile Friendliness</a></h3>
                    </div>
                </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog3.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>19 June 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i><a routerLink="/blog">SEO & Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">15 SEO Practices Website Architecture</a></h3>
                    </div>
                </div>
            </div> -->
        </div>
    </div>

    <div class="blog-shape">
        <div class="shape1"><img loading="lazy" src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img loading="lazy" src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img loading="lazy" src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img loading="lazy" src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div>
<!-- <div class="brand-logo-area">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="brand-logo-area  pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 45px;" src="assets/img/brand/pic1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 125px; height: 55px;" src="assets/img/brand/pic2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 45px;" src="assets/img/brand/pic3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 45px;" src= "assets/img/brand/intel.png"alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 45px;" src="assets/img/brand/amazon1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img loading="lazy" style="width: 135px; height: 45px;"  src="assets/img/brand/pic4.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="project-area">
    <div class="container">
        <div class="project-content">
            <h2>Already Interested! Do You Have Any Project to Working With?</h2>
            <a routerLink="/contact" class="project-btn">Get started <i class='bx bx-plus'></i></a>
        </div>
    </div>

    <div class="project-shape">
        <div class="shape1"><img loading="lazy" src="assets/img/shape/shape17.png" alt=""></div>
        <div class="shape2"><img loading="lazy" src="assets/img/shape/shape18.png" alt=""></div>
    </div>
</div>

<!-- <div class="data-table-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="data-table-content">
                    <h2>To decode the Power of Technology</h2>
                    <a routerLink="/contact" class="default-btn">Get started <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="data-table-img">
                    <img src="assets/img/data-table-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-two></app-footer-style-two>
