export const urls = {
  // job
  joblist: 'admin/staticsite/jobList',
  applyJob:'admin/staticsite/applyJob',

  //contact
  contactUs: 'admin/staticsite/contactUs',

  //bloglist
  blogList: 'admin/staticsite/blogList',

  locationList: 'admin/staticsite/locationList',

  faqList:'admin/staticsite/faqList'
};
