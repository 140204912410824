<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>FAQ</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>FAQ</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img loading="lazy" src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img loading="lazy" src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img loading="lazy" src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img loading="lazy" src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="faq-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">FAQ</span>
            <h2 class="h2-color2">Frequently Asked Questions</h2> 
        </div>

        <div class="row pt-45">
<!--            <div class="col-lg-6 col-md-12" *ngFor="let item of faqList;let i=index">-->
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion" >
                    <ul class="accordion" id="item1">


                    <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> Why should I consider temporary employment with Anstek?</a>
                            <div class="accordion-content">
                                <p>Working for Anstek can provide you with the opportunity to gain on-the-job work experience at Anstek, one of the largest and most diversified global financial services firms in the industry. As a Anstek employee you will benefit from:</p>
                                <ul>
                                    <li>Competitive pay</li>
                                    <li> Challenging work</li>
                                    <li>Experience in an exciting industry</li>
                                    <li> Exposure to new technology</li>
                                    <li>A supportive employer</li>
                                    <li> Opportunities to build your professional network</li>
                                </ul>
                            </div>
                        </li>



                         <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i>How do I apply for a position?</a>
                            <div class="accordion-content"> <p>If you would like to apply for a specific job, please use the “Find Jobs” link at the top of the page, select the open job and click the Apply button. If you would like to be considered for appropriate positions as they become available, please “Upload Your Resume” using the link at the top of this page. If you are a match for a role, you will be contacted by a Anstek representative.</p>
                            </div>
                        </li>

                     <li class="accordion-item">
                            <a class="accordion-title " href="javascript:void(0)"><i class='bx bx-chevron-down'></i>  How soon can I start working?</a>
                            <div class="accordion-content">
                                <p>We are committed to providing the most qualified, best-matched candidates for every position we fill. To ensure this, you’ll be asked to go through a pre-hire screening and background verification process. Then you’ll be ready to start accepting assignments. While we can’t guarantee that there will be an immediate opening that matches your skills and interests, there are new positions coming into Anstek every day. We recommend you check our website regularly or contact your Anstek representative periodically to learn what’s available.</p>
                            </div>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title " href="javascript:void(0)"><i class='bx bx-chevron-down'></i> What benefits do you offer?</a>
                            <div class="accordion-content">
                                <p>Anstek offers a wide range of benefits competitive for the temporary staffing industry. Please visit the Benefits page for information or speak with your Anstek representative.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

      <div class="col-lg-6 col-md-12">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> Does Anstek require any background checks?</a>
                            <div class="accordion-content">
                                <p>A background check on all potential employees is required and will include employment and education verification, a criminal, regulatory and credit check (where applicable), a drug screen according to Federal guidelines, and fingerprinting.</p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> How and when will I be paid?</a>
                            <div class="accordion-content">
                                <p>Your paycheck is calculated on an hourly W2 basis and issued weekly through direct deposit or by mail. You can easily enter your hours online through our automated time entry and approval system.</p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> Do Anstek employees ever transition from temporary to permanent positions?</a>
                            <div class="accordion-content">
                                <p>It is not uncommon for our temporary employees to be hired into regular full-time positions at  Anstek . To learn more, talk to your  Anstek representative.</p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> Will I receive another assignment when my current assignment ends?</a>
                            <div class="accordion-content ">
                                <p>Anstek frequently receives requests for new employees. We encourage you to work with your HR Manager prior to the end of your assignment to explore the availability of new positions.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="faq-contact pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">FAQ</span>
            <h2>Tell Us Your Qualms</h2>
        </div>

        <div class="row pt-45 align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="faq-img">
                    <img loading="lazy" src="assets/img/faq-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="faq-contact-wrap">
                    <div class="contact-form">
                        <!-- <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="text" name="name" id="name" class="form-control" placeholder="Your Name*">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="E-mail*">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-phone'></i>
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-file'></i>
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <i class='bx bx-envelope'></i>
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn border-radius">Send Message <i class='bx bx-plus'></i></button>
                                </div>
                            </div>
                        </form> -->
                        <form [formGroup]="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="text" class="form-control"
                                              formControlName="name" required placeholder="Your Name*"
                                              [ngClass]="{'is-invalid': !contactForm.get('name').valid && (contactForm.get('name').dirty || contactForm.get('name').touched)}"
                                        >
                                        <label *ngIf="!contactForm.get('name').valid && (contactForm.get('name').dirty || contactForm.get('name').touched)"
                                               class="error jquery-validation-error small form-text invalid-feedback">Please enter name.
                                        </label>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="email" class="form-control"
                                              formControlName="email" required placeholder="E-mail*"
                                              [ngClass]="{'is-invalid': !contactForm.get('email').valid && (contactForm.get('email').dirty || contactForm.get('email').touched)}"
                                        >
                                        <label *ngIf="!contactForm.get('email').valid && (contactForm.get('email').dirty || contactForm.get('email').touched)"
                                             class="error jquery-validation-error small form-text invalid-feedback">Please enter email.
                                        </label>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-phone'></i>
                                        <input type="text" class="form-control"
                                               formControlName="phone_number" placeholder="Your Phone*"
                                               [ngClass]="{'is-invalid': !contactForm.get('phone_number').valid && (contactForm.get('phone_number').dirty || contactForm.get('phone_number').touched)}"
                                        >
                                        <label *ngIf="!contactForm.get('phone_number').valid && (contactForm.get('phone_number').dirty || contactForm.get('phone_number').touched)"
                                              class="error jquery-validation-error small form-text invalid-feedback">Please enter phone_number.
                                        </label>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-file'></i>
                                        <input type="text" formControlName="msg_subject" class="form-control" placeholder="Your Subject*"
                                             [ngClass]="{'is-invalid': !contactForm.get('msg_subject').valid && (contactForm.get('msg_subject').dirty || contactForm.get('msg_subject').touched)}"
                                        >
                                        <label *ngIf="!contactForm.get('msg_subject').valid && (contactForm.get('msg_subject').dirty || contactForm.get('msg_subject').touched)"
                                              class="error jquery-validation-error small form-text invalid-feedback">Please enter msg_subject.
                                        </label>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <i class='bx bx-envelope'></i>
                                        <textarea  class="form-control" formControlName="message"  cols="30" rows="8"  placeholder="Your Message*"
                                            [ngClass]="{'is-invalid': !contactForm.get('message').valid && (contactForm.get('message').dirty || contactForm.get('message').touched)}">
                                        </textarea>
                                        <label *ngIf="!contactForm.get('message').valid && (contactForm.get('message').dirty || contactForm.get('message').touched)"
                                        class="error jquery-validation-error small form-text invalid-feedback">Please enter message.
                                  </label>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn border-radius"
                                    (click)="addMessage(contactForm)">Send Message <i class='bx bx-plus'></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-brands></app-brands>

<app-footer-style-two></app-footer-style-two>
