<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img loading="lazy" src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img loading="lazy" src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img loading="lazy" src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img loading="lazy" src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-widget-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2 class="h2-color">We are Flexible enough to <b>Provide You Best</b></h2>
        </div>
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6" *ngFor="let row of servicesFilterData;let i = index">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details" (click)="serviceDetailsPage(row)"><img class="float-start" [src]="row.cover_img" alt="Images"></a >
                    <h3 class="text-start"><a routerLink="/services-details" (click)="serviceDetailsPage(row)">{{row.title}}</a></h3>
                    <p class="text-start">{{row.short_desc | slice : 0 : 175}}..<a style="color:#ff2500;"
                                                                                   routerLink="/services-details"
                                                                                   (click)="serviceDetailsPage(row)">show more</a></p>
                    <div class="services-card-shape"
                    ><img loading="lazy" src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details"><img class="float-start" src="assets/img/service/service-icon5.png" alt="Images"></a>
                    <h3 class="text-start"><a routerLink="/services-details">Web Design</a></h3>
                    <p class="text-start">Our team of competent Web designers use their creative and software programming skills to design, build and improve websites.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details"><img class="float-start" src="assets/img/service/service-icon4.png" alt="Images"></a> 
                    <h3 class="text-start"><a routerLink="/services-details">Web Development</a></h3>
                    <p class="text-start">Our web experiences are high-performing, feature-packed and digitally transformative, designed to be user-friendly, and secure.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details"><img class="float-start" src="assets/img/service/service-icon3.png" alt="Images"></a>
                    <h3 class="text-start"><a routerLink="/services-details">Artificial Intelligence</a></h3>
                    <p class="text-start">Our increased focus on infusing data and AI across its offerings that enables 
                        clients to generate higher value from their digital transformation initiatives.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details"><img class="float-start" src="assets/img/service/blockchain.png" alt="Images"></a>
                    <h3 class="text-start"><a routerLink="/services-details">Blockchain Development</a></h3>
                    <p class="text-start">Building business logic to running blockchain nodes, we understand how to develop a decentralized platform for NFTs, metaverse, tokens and dApps.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details"><img class="float-start" src="assets/img/service/dataAnalytics.png" alt="Images"></a>
                    <h3 class="text-start"><a routerLink="/services-details">Data Analytics</a></h3>
                    <p class="text-start">Data analyst is responsible for organizing data related to sales numbers, and logistics. They utilize technical expertise to ensure data is accurate and high-quality.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details"><img class="float-start" src="assets/img/service/cyber-security.png" alt="Images"></a>
                    <h3 class="text-start"><a routerLink="/services-details">Cyber Security</a></h3>
                    <p class="text-start">Cyber threats are growing at an exponential rate globally. The current technologies let us into a complex environment of trade wars and hacktivism.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details"><img class="float-start" src="assets/img/service/web3.png" alt="Images"></a>
                    <h3 class="text-start"><a routerLink="/services-details">Web 3.0</a></h3>
                    <p class="text-start">Our passion is delivering Web 3.0, a decentralized and fair internet where users control their own data, identity and destiny.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="float-start w-100" routerLink="/services-details"><img class="float-start" class="float-start" src="assets/img/service/devops.png" alt="Images"></a>
                    <h3 class="text-start"><a routerLink="/services-details">DevOps Services</a></h3>
                    <p class="text-start">DevOps helps us automate the processes between software development and IT teams.It helps organizations reduce costs, and improve service delivery.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div> -->


            <div  class="col-lg-12 col-md-12" *ngIf="servicesData.length > 0">
                <app-pagination
                        [totalRecords]="collectionSize" [recordsPerPage]="this.pageSize" (onPageChange)="displayActivePage($event)">
                </app-pagination>
            </div>
            
        </div>
    </div>
</div>

<app-brands></app-brands>
<!-- <div class="brand-logo-area  pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img style="width: 135px; height: 45px;" src="assets/img/brand/standrad1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img style="width: 135px; height: 45px;" src="assets/img/brand/amazon1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img style="width: 135px; height: 45px;" src="assets/img/brand/tata.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img style="width: 135px; height: 45px;" src="assets/img/brand/tcs.jpg" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img style="width: 135px; height: 45px;" src="assets/img/brand/bmw.jpg" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img style="width: 135px; height: 45px;"  src="assets/img/brand/amazon1.png" alt="Images">
                </div>
            </div>
        </div>
    </div> -->
<!-- </div> -->

<app-footer-style-two></app-footer-style-two>
