<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services Details</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services Details</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img loading="lazy" src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img loading="lazy" src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img loading="lazy" src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img loading="lazy" src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img [src]="serviceData?.cover_img" alt="Images">
                        <h2>{{serviceData?.title}}</h2>
                        <p>{{serviceData?.short_desc}}</p>
                        <p>{{serviceData?.long_desc}}</p>
                    </div>

<!--                    <div class="row">-->
<!--                        <div class="col-lg-6 col-sm-6">-->
<!--                            <div class="services-content-card">-->
<!--                                <h3>Real-time Keyword Data</h3>-->
<!--                                <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p>-->
<!--                            </div>-->
<!--                        </div>-->

<!--                        <div class="col-lg-6 col-sm-6">-->
<!--                            <div class="services-content-card">-->
<!--                                <h3>Scalable Keyword Growth</h3>-->
<!--                                <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p>-->
<!--                            </div>-->
<!--                        </div>-->

<!--                        <div class="col-lg-6 col-sm-6">-->
<!--                            <div class="services-content-card">-->
<!--                                <h3>ROI Obsessed Keywords</h3>-->
<!--                                <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p>-->
<!--                            </div>-->
<!--                        </div>-->

<!--                        <div class="col-lg-6 col-sm-6">-->
<!--                            <div class="services-content-card">-->
<!--                                <h3>Total Keyword Growth Visibility</h3>-->
<!--                                <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="content-widget-img">
                                    <img loading="lazy" src="assets/img/service/service-details-img2.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="content-widget-text">
                                    <h2>{{serviceData.title_two}}</h2>
                                    <p>{{serviceData?.short_desc_two}}</p>
                                    <ul>
                                        <li *ngFor="let item of serviceData?.key_point"><i class='bx bx-check'></i>{{item.value}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>{{serviceData?.short_desc_three}}</p>

<!--                    <div class="services-widget-list">-->
<!--                        <ul>-->
<!--                            <li class="active">-->
<!--                                <span>75%</span>-->
<!--                                <div class="content list1">-->
<!--                                    <h3>SEO & Marketing</h3>-->
<!--                                </div>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <div class="content list2">-->
<!--                                    <h3>Keywords Results</h3>-->
<!--                                </div>-->
<!--                                <span>66%</span>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <span>43%</span>-->
<!--                                <div class="content list3">-->
<!--                                    <h3>Google Analytics</h3>-->
<!--                                </div>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <div class="content list2">-->
<!--                                    <h3>Off Page SEO</h3>-->
<!--                                </div>-->
<!--                                <span>15%</span>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </div>-->
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <div class="widget-category">
                        <h3>Top 5 Services</h3>

                        <ul>
                            <li style="cursor:pointer;" *ngFor="let row of servicesList;let i = index"><a
                                    (click)="serviceDetailsPage(row)">{{row?.title}}</a></li>
                        </ul>
                    </div>

                    <div class="contact-widget">
                        <h2>Contact Info</h2>

                        <ul>
                            <li>
                                <i class="flaticon-telephone"></i>
                                <div class="content">
                                    <h3>Phone: </h3>
                                    <span><a href="tel:+564325677896">+1 (385) 364-4242</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-email-1"></i>
                                <div class="content">
                                    <h3>Email:</h3>
                                    <span><a href="mailto:hello@ribo.com">info@anstek.com</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-planet-earth"></i>
                                <div class="content">
                                    <h3>Location:</h3>
                                    <span>520 Central Pkwy E, Ste 106 <br>Plano, TX 75074</span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <!-- <div class="services-widget-two">
                        <h2>Brochures</h2>

                        <ul>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>01:</h3>
                                    <span><a routerLink="/services-details">PDF Download</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>02:</h3>
                                    <span><a routerLink="/services-details">Services Details.txt</a></span>
                                </div>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<app-brands></app-brands>
<!-- <div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-two></app-footer-style-two>
