<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact Us</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img loading="lazy" src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img loading="lazy" src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img loading="lazy" src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img loading="lazy" src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="contact-area pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Contact</span>
            <h2>Reach out to us to experience our exceptional software services</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-planet-earth"></i>
                    <h3>Office Location</h3>
                    <p>520 Central Pkwy E, Ste 106</p>
                    <p>Plano, TX 75074</p>
                    <a target="_blank" href="https://www.google.com/maps/place/520+Central+Pkwy+E+%23106,+Plano,+TX+75074,+USA/@33.0257484,-96.7101305,17z/data=!3m1!4b1!4m6!3m5!1s0x864c18fd9b0cc77f:0x83f213b194bf762a!8m2!3d33.0257484!4d-96.7075556!16s%2Fg%2F11rdb3b7sy?entry=ttu&g_ep=EgoyMDI0MDkyNC4wIKXMDSoASAFQAw%3D%3D"
                       class="contact-card-btn">Direction <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-email"></i>
                    <h3>Contact</h3>
                    <p>info@anstek.com</p>
                    <p>Office +1 (385) 364-4242</p>
                    <a href="tel:+13853644242" class="contact-card-btn">Know More <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-card">
                    <i class="flaticon-clock"></i>
                    <h3>Hours of Operation</h3>
                    <p>Mon- Fri </p>
                    <p>9.00 AM - 05.00 PM </p>
                    <a href="mailto:info@anstek.com" class="contact-card-btn">Support <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-img">
                    <!-- <img loading="lazy" src="assets/img/about/about-img4.png" alt="Images"> -->
                    <img loading="lazy" src="assets/img/faq-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="contact-wrap">
                    <div class="contact-form">
                        <div class="section-title">
                            <span class="sp-before sp-after">Contact</span>
                            <h2>Connect With Us</h2>
                        </div>

                        <form [formGroup]="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="text" class="form-control"
                                              formControlName="name" required placeholder="Your Name*"
                                              [ngClass]="{'is-invalid': !contactForm.get('name').valid && (contactForm.get('name').dirty || contactForm.get('name').touched)}"
                                        >
                                        <label *ngIf="!contactForm.get('name').valid && (contactForm.get('name').dirty || contactForm.get('name').touched)"
                                               class="error jquery-validation-error small form-text invalid-feedback">Please enter name.
                                        </label>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="email" class="form-control"
                                              formControlName="email" required placeholder="E-mail*"
                                              [ngClass]="{'is-invalid': !contactForm.get('email').valid && (contactForm.get('email').dirty || contactForm.get('email').touched)}"
                                        >
                                        <label *ngIf="!contactForm.get('email').valid && (contactForm.get('email').dirty || contactForm.get('email').touched)"
                                             class="error jquery-validation-error small form-text invalid-feedback">Please enter email.
                                        </label>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-phone'></i>
                                        <input type="text" class="form-control"
                                               formControlName="phone_number" placeholder="Your Phone*"
                                               [ngClass]="{'is-invalid': !contactForm.get('phone_number').valid && (contactForm.get('phone_number').dirty || contactForm.get('phone_number').touched)}"
                                        >
                                        <label *ngIf="!contactForm.get('phone_number').valid && (contactForm.get('phone_number').dirty || contactForm.get('phone_number').touched)"
                                              class="error jquery-validation-error small form-text invalid-feedback">Please enter phone_number.
                                        </label>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-file'></i>
                                        <input type="text" formControlName="msg_subject" class="form-control" placeholder="Your Subject*"
                                             [ngClass]="{'is-invalid': !contactForm.get('msg_subject').valid && (contactForm.get('msg_subject').dirty || contactForm.get('msg_subject').touched)}"
                                        >
                                        <label *ngIf="!contactForm.get('msg_subject').valid && (contactForm.get('msg_subject').dirty || contactForm.get('msg_subject').touched)"
                                              class="error jquery-validation-error small form-text invalid-feedback">Please enter msg_subject.
                                        </label>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <i class='bx bx-envelope'></i>
                                        <textarea  class="form-control" formControlName="message"  cols="30" rows="8"  placeholder="Your Message*"
                                            [ngClass]="{'is-invalid': !contactForm.get('message').valid && (contactForm.get('message').dirty || contactForm.get('message').touched)}">
                                        </textarea>
                                        <label *ngIf="!contactForm.get('message').valid && (contactForm.get('message').dirty || contactForm.get('message').touched)"
                                        class="error jquery-validation-error small form-text invalid-feedback">Please enter message.
                                  </label>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn border-radius"
                                    (click)="addMessage(contactForm)">Send Message <i class='bx bx-plus'></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-map">
    <div class="container-fluid m-0 p-0">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3345.1663210804745!2d-96.71013052448481!3d33.02574837356069!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c18fd9b0cc77f%3A0x83f213b194bf762a!2s520%20Central%20Pkwy%20E%20%23106%2C%20Plano%2C%20TX%2075074%2C%20USA!5e0!3m2!1sen!2sin!4v1727428580359!5m2!1sen!2sin" ></iframe>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
