import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/shared/services/auth.service';
import { urls } from 'src/shared/utils/urls';
import {DomSanitizer} from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SessionConstants } from 'src/shared/utils/sessionConstants';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit{
  jobList = [];
  locationList = [];
  webHost: any;
  searchControl: any;
  selectedLocation: any;
  filteredJobs: any = [];
  collectionSize = 0;
  activePage:number = 1;
  pageSize = 3;
  applyjobForm: FormGroup;
  



  constructor(private fb: FormBuilder,private auth:AuthService, public sanitizer:DomSanitizer,private router: Router){
    this.webHost = environment.imgUrl;
    this.selectedLocation = '0';
  }


  ngOnInit(): void {
    this.getjoblist();
    this.getlocationList();
    this.applyjobForm = this.fb.group({
      name : ['', Validators.required],
      email : ['', Validators.required],
      phone_number : ['', Validators.required],
      message: ['', Validators.required],
      resume:['', Validators.required],
      fileSource: ['',Validators.required],
      // job_title:['']
    });
    
   
  }

  displayActivePage(activePageNumber:number){

    console.log(typeof this.searchControl);
    let arr = [];
    if (this.searchControl != undefined && this.searchControl != ''){
      console.log('1');
       arr = JSON.parse(JSON.stringify(this.filteredJobs));
    }else{
      console.log('2');
      arr = JSON.parse(JSON.stringify(this.jobList));
    }
    this.filteredJobs = [];
    let nextNumber: any;
    let currentNumber = (activePageNumber-1) * this.pageSize;
    nextNumber = (activePageNumber) * this.pageSize;
    console.log(currentNumber , 'from');
    console.log(nextNumber , 'to');
    for (let i=currentNumber;i < nextNumber;i++){
      if(this.filteredJobs.length < this.pageSize && i < arr.length){
        this.filteredJobs.push(arr[i]);
      }
    }
    this.activePage = activePageNumber;
  }

  getjoblist(){
    const url = urls.joblist;
    const payload = {
        platform: 'web',
        role_id:1,
        user_id:1,
        company_id:''
    }
    this.auth.postService(payload, url).subscribe(
      (successData) => {
        if (successData.IsSuccess) {
          this.jobList = successData.ResponseObject;
          this.collectionSize = this.jobList.length;
          this.displayActivePage(1);
        }
      },
      (err) => {
        console.log(err);
      }
  )
  }

  getlocationList(){
    const url = urls.locationList;
    const payload = {
        platform: 'web',
        role_id:1,
        user_id:1,
        company_id:''
    }
    this.auth.postService(payload, url).subscribe(
      (successData) => {
        if (successData.IsSuccess) {
          this.locationList = successData.ResponseObject;
        }
      },
      (err) => {
        console.log(err);
      }
  )
  }

  searchFilter(){
    this.filteredJobs = this.jobList.filter((element)=>{
      return element.title.toLowerCase().indexOf(this.searchControl.toLowerCase()) > -1;
    });
    this.collectionSize = this.filteredJobs.length;
    this.displayActivePage(1);
  }

  browseFile(event) {
    const reader = new FileReader();
    const finalData = [];
    const max = 1;
    let validfiles = [...event.target.files];
    validfiles?.forEach(async (item , index) => {
      const fileSize = item.size;
      const fileMb = fileSize / 1024 / 1024;
      if (fileMb >= max) {
        alert(`File size exceeds ${max} MiB`);
      } else {
        const res = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(item);
          reader.onload = () => {
            finalData.push({name : item.name , type: item.type, image: reader.result, size: item.size});
            resolve(reader.result);
          };
          reader.onerror = error => reject(error);
        });
        if (validfiles.length === finalData.length){
          console.log(finalData ,'final data');
          let files = [];
          for (const item of finalData) {
            console.log(item, 'item');
            item.progress = 0;
            const split = item.image.split(',');
            item.image = split[1];
            files.push(item);
          }
          this.applyjobForm.patchValue({
            fileSource: files
          });
        }
      }
    });
  }

  applyjob(value){
    console.log(this.applyjobForm.value,'value');
    Object.keys(this.applyjobForm.controls).forEach(val => {
      this.applyjobForm.controls[val].markAsTouched({onlySelf: true});
    });
    let payload: any;
    if (this.applyjobForm.valid) {
      payload = {
        platform: 'web',
         role_id: '1',
         user_id: '1',
         full_name: this.applyjobForm.get('name').value,
         email_id : this.applyjobForm.get('email').value,
         contact_number : this.applyjobForm.get('phone_number').value,
         message: this.applyjobForm.get('message').value,
         company_id: '1',
         resume:this.applyjobForm.get('fileSource').value,
         job_title: value.title, 
      };
      console.log(payload ,'payload ');
       let url = urls.applyJob;
       this.auth.postService(payload, url).subscribe(
        (successData: any) => {
          if (successData.IsSuccess) {
            // this.toast.success(successData.ResponseObject, '');
            this.applyjobForm.reset();
          } else {
            // this.toast.error(successData.ErrorObject, '');
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  contact(){
    this.router.navigate(['/contact']);
  }
  careerDetailsPage(data){
    localStorage.setItem(SessionConstants.careersDetails, JSON.stringify(data));
    console.log(data,'data');
    this.router.navigate(['/careers-details']);
  }

  
}
