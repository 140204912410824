<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img style="max-width: 151px !important;width: 112px !important;height: 45px;"
                                            src="assets/img/logo/logof.png" alt="Logo"></a>
    </div>

    <div class="main-nav nav-bar-two">
        <div class="container-fluid">
            <nav class="container-max-2 navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img style="width: 200px !important;width: 151px !important;
    height: 94px;" src="assets/img/logo/logo.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item">
                            <a routerLink="/" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Home
                                <!--                                <i class='bx bx-plus'></i>-->
                            </a>

<!--                            <ul class="dropdown-menu">-->
<!--                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">SEO Agency</a></li>-->

<!--                                <li class="nav-item"><a routerLink="/ai-startup" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">AI Startup</a></li>-->

<!--                                <li class="nav-item"><a routerLink="/machine-learning" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Machine Learning</a></li>-->
<!--                            </ul>-->
                        </li>

                        <li class="nav-item"><a id="about" routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                        <li class="nav-item">
                            <a id="services" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" routerLink="/services" class="nav-link">Services</a>
                            <!--                            <ul class="dropdown-menu">-->
                            <!--                                <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>-->

                            <!--                                <li class="nav-item"><a routerLink="/services-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Details</a></li>-->
                            <!--                            </ul>-->
                        </li>
                        <li class="nav-item">
                            <a id="careers" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/careers" class="nav-link" class="nav-link">Careers</a>
                        </li>
                        <li class="nav-item">
                            <a id="faq" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/faq" class="nav-link" class="nav-link">FAQ</a>
                        </li>
                        <li class="nav-item">
                            <a id="blog" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/blog" class="nav-link">Blogs</a>

<!--                            <ul class="dropdown-menu">-->
<!--                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>-->

<!--                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>-->
<!--                            </ul>-->
                        </li>

                        <li class="nav-item"><a id="contact" routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>

<!--                        <li class="nav-item">-->
<!--                            <a href="javascript:void(0);" class="nav-link">Pages <i class='bx bx-plus'></i></a>-->

<!--                            <ul class="dropdown-menu">-->
<!--                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>-->

<!--                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>-->

<!--                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>-->

<!--                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>-->

<!--                                <li class="nav-item">-->
<!--                                    <a href="javascript:void(0);" class="nav-link">Case study <i class='bx bx-plus'></i></a>-->

<!--                                    <ul class="dropdown-menu">-->
<!--                                        <li class="nav-item"><a routerLink="/case-study" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case study</a></li>-->

<!--                                        <li class="nav-item"><a routerLink="/case-study-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case study Details</a></li>-->
<!--                                    </ul>-->
<!--                                </li>-->

<!--                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>-->

<!--                                <li class="nav-item"><a routerLink="/sign-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign In</a></li>-->

<!--                                <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>-->

<!--                                <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>-->

<!--                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>-->

<!--                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>-->
<!--                            </ul>-->
<!--                        </li>-->




                    </ul>

                    <div class="side-nav d-in-line align-items-center">
<!--                        <div class="side-item">-->
<!--                            <div class="search-box">-->
<!--                                <i class="flaticon-loupe"></i>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="side-item">-->
<!--                            <div class="user-btn">-->
<!--                                <a routerLink="/"><i class="flaticon-contact"></i></a>-->
<!--                            </div>-->
<!--                        </div>-->
                        <div style="margin-bottom: 6px;" class="side-item footer-social-icon">
                            <ul class="social-link">
                                <li class="side-item"><a href="https://www.linkedin.com/company/anstek-inc/" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li class="side-item"><a href="https://twitter.com/anstek_utah" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li class="side-item"><a href="https://www.instagram.com/Anstek_Inc/" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li class="side-item"><a href="https://www.facebook.com/people/Anstek-INC/100091035071175/" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            </ul>
                        </div>
                        <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/contact" class="nav-menu-btn">Contact Us <i class='bx bx-plus'></i></a>
                            </div>
                        </div>

                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="side-nav-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="circle-inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>

            <div class="container">
                <div class="side-nav-inner">
                    <div class="side-nav justify-content-center  align-items-center">
                        <div class="side-item">
                            <div class="search-box">
                                <i class="flaticon-loupe"></i>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="user-btn">
                                <a routerLink="/"><i class="flaticon-contact"></i></a>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/contact" class="nav-menu-btn">Contact Us <i class='bx bx-plus'></i></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-layer"></div>

            <div class="search-close">
                <span class="search-close-line"></span>
                <span class="search-close-line"></span>
            </div>

            <div class="search-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class="flaticon-loupe"></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
