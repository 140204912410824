import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/shared/services/auth.service';
import { urls } from 'src/shared/utils/urls';
import {DomSanitizer} from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { SessionConstants } from 'src/shared/utils/sessionConstants';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blogList = [];
  baseImageUrl: string;
  filteredBlogs: any = [];
  collectionSize = 0;
  activePage:number = 1;
  pageSize = 6;

  constructor(private auth: AuthService, public sanitizer: DomSanitizer) { 
    this.baseImageUrl = environment.imgUrl;
  }

  ngOnInit(): void {
    this.getblogList();
  }

  getblogList(){
    const url = urls.blogList;
    let payload: any = {
        platform: 'web',
        role_id: 1,
        user_id: 1,
    }
    this.auth.postService(payload, url).subscribe(
      (successData: any) => {
        if (successData.IsSuccess) {
          // this.toast.success(successData.ResponseObject, '')
          let arr = [];
          successData.ResponseObject.forEach((item) => {
            if(item.status != '0'){
              arr.push(item);
            }
          });
          this.blogList = arr;
          this.collectionSize = this.blogList.length;
          this.displayActivePage(1);
        } else {
          // this.toast.error(successData.ErrorObject, '');
        }
      },
      (err) => {
        console.log(err);
      }
  )
  }
  blogDetailsPage(data){
    localStorage.setItem(SessionConstants.blogDetails, JSON.stringify(data));
  }




  displayActivePage(activePageNumber:number){
    this.filteredBlogs = [];
    let arr = JSON.parse(JSON.stringify(this.blogList));
    let nextNumber: any;
    let currentNumber = (activePageNumber-1) * this.pageSize;
    nextNumber = (activePageNumber) * this.pageSize;
    for (let i=currentNumber;i < nextNumber;i++){
      if(this.filteredBlogs.length < this.pageSize && i < arr.length){
        this.filteredBlogs.push(arr[i]);
      }
    }
    this.activePage = activePageNumber;
  }

}
