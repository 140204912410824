import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators'
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,public meta: Meta,
              public titleService: Title) { }

  postService(data, url): any {
    const json = JSON.stringify(data);
    return this.http.post(url, json).pipe(
        map(this.extractData),
        catchError(this.handleError));
  }
  private extractData(res: Response): object {
    const body = res;
    return body || {};
  }

  private handleError(error: Response | any): any {
    let errMsg: string;
    if (error instanceof Response) {
      // const body = error.json() || '';
      const err = error || JSON.stringify(error);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return observableThrowError(error);
  }
  filterArr(val , originalArr, filteredArr) {
    if (val) {
      val = val.toLowerCase();
    } else {
      return filteredArr = [...originalArr];
    }

    const columns = Object.keys(originalArr[0]);
    if (!columns.length) {
      return;
    }

    const rows = originalArr.filter(function(d) {
      for (let i = 0; i <= columns.length; i++) {
        const column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });
     filteredArr = rows;

     return filteredArr;
  }

  updateMeta(title, metaData:{name: string, content: string}){
    this.titleService.setTitle('Anstek Inc - Answer Technology');
    // this.meta.addTag({
    //   name: 'angular.ganatan',
    //   content: 'danny ganatan'
    // });
    // this.meta.updateTag(
    //     {
    //       name: 'description',
    //       content: 'Cette application a été développée avec Angular version 13.1.1 et bootstrap 5.1.3' +
    //           ' Elle applique le Routing, le Lazy loading, le Server side rendering et les Progressive Web App (PWA)'
    //     });
  }
}


