<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
  <div class="container">
    <div class="inner-title text-center">
      <h3>Career Details</h3>
      <ul>
        <li><a href="index.html">Home</a></li>
        <li><i class="bx bx-chevron-right"></i></li>
        <li>Career Details</li>
      </ul>
    </div>
  </div>

  <div class="inner-banner-shape">
    <div class="shape-one">
      <img loading="lazy" src="assets/img/inner-banner/banner-shape1.png" alt="Images" />
    </div>
    <div class="shape-two">
      <img loading="lazy" src="assets/img/inner-banner/banner-shape2.png" alt="Images" />
    </div>
    <div class="shape-three">
      <img loading="lazy" src="assets/img/inner-banner/banner-shape3.png" alt="Images" />
    </div>
    <div class="inner-banner-dots-2">
      <img loading="lazy" src="assets/img/shape/dots-shape.png" alt="Images" />
    </div>
  </div>
</div>
<div class="container">
    <div class="mt-2 mb-2 d-flex justify-content-between">
        <div class="">
            <h3>{{careerData.title}}</h3>
        </div>
        <div class="text-end">
            <button style="padding-right: 22px" class="default-btn border-radius"
                    data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm"
            >Apply now </button>
        </div>
    </div>

  <div class="row">
    <img loading="lazy"
      src="assets/img/brand/carrrier bg.png"
      alt=""
      style="border-radius: 20px"
    />
  </div>
</div>
<div class="container mt-2">

    <div class="icon d-flex flex-row">
        <div class="">
            <p ><i class="bx bx-time-five p-1"></i>Today{{careerData.time}}</p>
        </div>
        <div class="mx-3">
            <p ><i class="bx bx-calendar p-1"></i>{{careerData.postal_date}}</p>
        </div>
    </div>

</div>
<div class="container ">

<!--  <div class="d-flex justify-content-center">-->
<!--    <div class="col-lg-10 col-md-12 col-sm-12 col-12">-->
<!--      <div class="job-overview">-->
<!--        <h5 class="border-bottom pb-15 mb-30" style="margin-bottom:30px;padding-bottom:23px;">Employment Information</h5>-->
<!--  -->
<!--        <div class="row mt-2">-->
<!--            <div class="col-md-6 d-flex">-->
<!--                <div class="sidebar-icon-item">-->
<!--                    <img src="https://jthemes.com/themes/wp/jobbox/wp-content/themes/jobbox/assets/imgs/page/job-single/industry.svg" alt="Industry">-->
<!--                </div>-->
<!--                <div class="sidebar-text-info ml-10">-->
<!--                    <span class="text-description industry-icon">Industry</span>-->
<!--                    <strong class="small-heading">{{careerData.category}}</strong>-->
<!--                </div>-->
<!--            </div>-->
<!--  -->
<!--            <div class="col-md-6 d-flex">-->
<!--                <div class="sidebar-icon-item">-->
<!--                    <img src="https://jthemes.com/themes/wp/jobbox/wp-content/themes/jobbox/assets/imgs/page/job-single/job-level.svg" alt="Job level">-->
<!--                </div>-->
<!--                <div class="sidebar-text-info ml-10">-->
<!--                    <span class="text-description joblevel-icon">Qualification</span>-->
<!--                    <strong class="small-heading" >{{careerData.education}}</strong>-->
<!--                </div>-->
<!--            </div>-->
<!--  -->
<!--        </div>-->
<!--  -->
<!--        <div class="row mt-25">-->
<!--  -->
<!--            <div class="col-md-6 d-flex">-->
<!--                <div class="sidebar-icon-item">-->
<!--                    <img src="https://jthemes.com/themes/wp/jobbox/wp-content/themes/jobbox/assets/imgs/page/job-single/salary.svg" alt="Salary">-->
<!--                </div>-->
<!--                <div class="sidebar-text-info ml-10">-->
<!--                    <span class="text-description salary-icon">Salary</span>-->
<!--                    <strong class="small-heading"><span class="card-text-price">$500</span></strong>-->
<!--                </div>-->
<!--            </div>-->
<!--  -->
<!--            <div class="col-md-6 d-flex">-->
<!--                <div class="sidebar-icon-item">-->
<!--                    <img src="https://jthemes.com/themes/wp/jobbox/wp-content/themes/jobbox/assets/imgs/page/job-single/experience.svg" alt="Experience">-->
<!--                </div>-->
<!--                <div class="sidebar-text-info ml-10">-->
<!--                    <span class="text-description experience-icon">Experience</span>-->
<!--                    <strong class="small-heading" >{{careerData.experience}}</strong>-->
<!--                </div>-->
<!--            </div>-->
<!--  -->
<!--        </div>-->
<!--  -->
<!--        <div class="row mt-25">-->
<!--  -->
<!--            <div class="col-md-6 d-flex">-->
<!--                <div class="sidebar-icon-item">-->
<!--                    <img src="https://jthemes.com/themes/wp/jobbox/wp-content/themes/jobbox/assets/imgs/page/job-single/job-type.svg" alt="Job type">-->
<!--                </div>-->
<!--                <div class="sidebar-text-info ml-10">-->
<!--                    <span class="text-description jobtype-icon">Job type</span>-->
<!--                    <strong class="small-heading" >{{careerData.shift_type}}</strong>-->
<!--                </div>-->
<!--            </div>-->
<!--  -->
<!--            <div class="col-md-6 d-flex">-->
<!--                <div class="sidebar-icon-item">-->
<!--                    <img src="https://jthemes.com/themes/wp/jobbox/wp-content/themes/jobbox/assets/imgs/page/job-single/deadline.svg" alt="Deadline">-->
<!--                </div>-->
<!--                <div class="sidebar-text-info ml-10">-->
<!--                    <span class="text-description">No Of openings</span>-->
<!--                    <strong class="small-heading" >{{careerData.no_of_opening}}</strong>-->
<!--                </div>-->
<!--            </div>-->
<!--  -->
<!--        </div>-->
<!--  -->
<!--        <div class="row mt-25">-->
<!--  -->
<!--            <div class="col-md-6 d-flex">-->
<!--                <div class="sidebar-icon-item">-->
<!--                    <img src="https://jthemes.com/themes/wp/jobbox/wp-content/themes/jobbox/assets/imgs/page/job-single/updated.svg" alt="Updated">-->
<!--                </div>-->
<!--                <div class="sidebar-text-info ml-10">-->
<!--                    <span class="text-description jobtype-icon">Updated</span>-->
<!--                    <strong class="small-heading" >{{careerData.postal_date}}</strong>-->
<!--                </div>-->
<!--            </div>-->
<!--  -->
<!--            <div class="col-md-6 d-flex">-->
<!--                <div class="sidebar-icon-item">-->
<!--                    <img src="https://jthemes.com/themes/wp/jobbox/wp-content/themes/jobbox/assets/imgs/page/job-single/location.svg" alt="Location">-->
<!--                </div>-->
<!--                <div class="sidebar-text-info ml-10">-->
<!--                    <span class="text-description">Location</span>-->
<!--                    <strong class="small-heading" >{{careerData.location_name}}</strong>-->
<!--                </div>-->
<!--            </div>-->
<!--  -->
<!--        </div>-->
<!--    </div>-->
<!--    </div>-->
<!--  </div>-->
  <div class="modal fade" role="dialog" id="ModalApplyJobForm" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="ModalApplyJobFormLabel" aria-hidden="true">
      <div class="modal-dialog">
          <div class="modal-content">
              <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalLabel" style="background-color:white;color: blue;">Job application</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="applyjobForm.reset('')" aria-label="Close"></button>
              </div>
              <div class="modal-body text-danger">
                  <form [formGroup]="applyjobForm" method="post">
                      <div class="input input-animate">
                          <!-- <h5  class="name-job">{{careerData?.title}}</h5> -->
                          <span class="spin"></span>
                      </div>
                      <div class="form-group mb-3 mt-1">
                          <input class="form-control" type="text" readonly

                                 value="{{careerData?.title}}"

                          >
                      </div>
                      <div class="form-group mb-3">
                          <input type="text" class="form-control"
                                 formControlName="name" required placeholder="Your Name*"
                                 [ngClass]="{'is-invalid': !applyjobForm.get('name').valid && (applyjobForm.get('name').dirty || applyjobForm.get('name').touched)}"
                          >
                          <label *ngIf="!applyjobForm.get('name').valid && (applyjobForm.get('name').dirty || applyjobForm.get('name').touched)"
                                 class="error jquery-validation-error small form-text invalid-feedback">Name is Required.
                          </label>
                      </div>
                      <div class="form-group mb-3">
                          <input type="email" class="form-control"
                                 formControlName="email" required placeholder="E-mail*"
                                 [ngClass]="{'is-invalid': !applyjobForm.get('email').valid && (applyjobForm.get('email').dirty || applyjobForm.get('email').touched)}"
                          >
                          <label *ngIf="!applyjobForm.get('email').valid && (applyjobForm.get('email').dirty || applyjobForm.get('email').touched)"
                                 class="error jquery-validation-error small form-text invalid-feedback">Email is Required.
                          </label>
                      </div>
                      <div class="form-group mb-3">
                          <input type="text" class="form-control"
                                 formControlName="phone_number" placeholder="Your Phone*"
                                 [ngClass]="{'is-invalid': !applyjobForm.get('phone_number').valid && (applyjobForm.get('phone_number').dirty || applyjobForm.get('phone_number').touched)}"
                          >
                          <label *ngIf="!applyjobForm.get('phone_number').valid && (applyjobForm.get('phone_number').dirty || applyjobForm.get('phone_number').touched)"
                                 class="error jquery-validation-error small form-text invalid-feedback">Phone number is Required.
                          </label>
                      </div>
                      <div class="form-group mb-3">
                                                    <textarea  class="form-control" formControlName="message"  cols="30" rows="8"  placeholder="Your Message*"
                                                               [ngClass]="{'is-invalid': !applyjobForm.get('message').valid && (applyjobForm.get('message').dirty || applyjobForm.get('message').touched)}">
                                                    </textarea>
                          <label *ngIf="!applyjobForm.get('message').valid && (applyjobForm.get('message').dirty || applyjobForm.get('message').touched)"
                                 class="error jquery-validation-error small form-text invalid-feedback">Message is Required.
                          </label>
                      </div>
                      <div class="mb-3 ">
                          <input accept="application/pdf" formControlName="resume"  (change)="browseFile($event)" required=""  type="file" id="resume"
                                 [ngClass]="{'is-invalid': !applyjobForm.get('resume').valid && (applyjobForm.get('resume').dirty || applyjobForm.get('resume').touched)}">
                          <p>Upload your resume file. File types : .pdf and Max file size : 1mb</p>
                          <label *ngIf="!applyjobForm.get('resume').valid && (applyjobForm.get('resume').dirty || applyjobForm.get('resume').touched)"
                                 class="error jquery-validation-error small form-text invalid-feedback">Please choose file.
                          </label>
                      </div>
                      <div  class="text-center">
                          Do you need support?
                          <a (click)="contact()" style="color: blue;cursor: pointer;" data-bs-dismiss="modal"><u>Contact US</u></a>
                      </div>
                  </form>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-outline-danger" (click)="applyjobForm.reset('')" data-bs-dismiss="modal">Close</button>
                  <button type="submit" class="default-btn border-radius" style="padding: 4px 15px 5px 15px;" (click)="applyjob(careerData)">Submit</button>
              </div>
          </div>
      </div>
</div>
  
         
    <!-- <h5>Employment Information</h5> -->
    <!-- <div class="row justify-content-center">
            <div class="col-md-6 d-flex">
                <img style="height:18px;" src="https://jthemes.com/themes/wp/jobbox/wp-content/themes/jobbox/assets/imgs/page/job-single/industry.svg" alt="Industry">
                <div class="sidebar-text-info ml-10">
                    <span style="padding-left: 15px;">Industry</span>
                    <strong style="margin-left: 200px;">{{careerData.category}}</strong>
                </div>
            </div>
            <div class="col-md-6 d-flex">
                <img  src="https://jthemes.com/themes/wp/jobbox/wp-content/themes/jobbox/assets/imgs/page/job-single/job-level.svg" alt="Job level">
                <div class="sidebar-text-info ml-10 ">
                  
                    <span style="padding-left: 15px;">
                        Qualification</span>
                </div>
                <strong style="margin-left: 50px;">{{careerData.education}}</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 d-flex">
                <img src="https://jthemes.com/themes/wp/jobbox/wp-content/themes/jobbox/assets/imgs/page/job-single/salary.svg" alt="Salary">
                <div class="sidebar-text-info ml-10">
                    <span style="padding-left: 15px;">Salary</span>
                    <strong style="margin-left: 212px">$500</strong>
                </div>
            </div>
            <div class="col-md-6 d-flex">
                <img src="https://jthemes.com/themes/wp/jobbox/wp-content/themes/jobbox/assets/imgs/page/job-single/experience.svg" alt="Experience">
                <div class="sidebar-text-info ml-10">
                    <span style="padding-left: 15px;">Experience</span>
                    <strong style="margin-left: 60px;">{{careerData.experience}}</strong>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 d-flex">
                <img src="https://jthemes.com/themes/wp/jobbox/wp-content/themes/jobbox/assets/imgs/page/job-single/job-type.svg" alt="Job type">
                <div class="sidebar-text-info ml-10">
                    <span style="padding-left: 15px;">Job type</span>
                    <strong style="margin-left: 194px;">
                        {{careerData.shift_type}}</strong>
                </div>
            </div>
            <div class="col-md-6 d-flex">
                <img src="https://jthemes.com/themes/wp/jobbox/wp-content/themes/jobbox/assets/imgs/page/job-single/deadline.svg" alt="Deadline">
                <div class="sidebar-text-info ml-10">
                    <span style="padding-left: 15px;">No Of openings</span>
                    <strong style="margin-left: 26px;">{{careerData.no_of_opening}}</strong>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 d-flex">
                <img src="https://jthemes.com/themes/wp/jobbox/wp-content/themes/jobbox/assets/imgs/page/job-single/updated.svg" alt="Updated">
                <div class="sidebar-text-info ml-10">
                    <span style="padding-left: 15px;">
                        Updated</span>
                    <strong style="margin-left:190px;">
                        {{careerData.postal_date}}</strong>
                </div>
            </div>
            <div class="col-md-6 d-flex">
                <img src="https://jthemes.com/themes/wp/jobbox/wp-content/themes/jobbox/assets/imgs/page/job-single/location.svg" alt="Location">
                <div class="sidebar-text-info">
                    <span style="padding-left: 15px;">Location</span>
                    <strong style="margin-left: 60px;">
                      {{careerData.location_name}}</strong>
                </div>
            </div>
        </div> -->
    
    <div class="content-single mt-2">
<!--      <h4 >Job Description :</h4>-->
      <p [innerHTML]="careerData.short_description"></p>
      <!-- <h4 > Description :</h4> -->
      <p [innerHTML]="careerData.long_description"></p>
<!--      <h4>Essential Knowledge, Skills, and Experience:</h4>-->
<!--          <p>{{careerData.preffered_skills}}</p>-->
<!--          <p><strong>Communication:</strong>&nbsp;Communicate the results of UX activities within your product area to the design team department, cross-functional partners within your product area, and other interested Superformula team members using clear language that simplifies complexity.</p>-->
    </div>
    <!-- <div class="author-single">
      <span>Anstek</span>
    </div> -->
</div>




      <app-footer-style-two></app-footer-style-two>
    
