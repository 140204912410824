import { Component, OnInit } from '@angular/core';
import { SessionConstants } from 'src/shared/utils/sessionConstants';
import {DomSanitizer} from "@angular/platform-browser";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
 blogData : any='';
 webhost: any;

  constructor(public sanitizer: DomSanitizer) {
    this.webhost = environment.imgUrl;
   }

  ngOnInit(): void {
     this.blogData = JSON.parse(localStorage.getItem(SessionConstants.blogDetails));
    console.log(this.blogData,'blog');
   
  }

}
